// @ts-nocheck

import React, { FC, Suspense, lazy } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from 'swiper/modules';
// import { Pagination, Autoplay, EffectFade } from 'swiper/core';
import SwiperCore from 'swiper';

// Import Swiper styles
import "swiper/css";
import 'swiper/css/pagination';
import { SlideBottmHeading, SlideButton, SlideContent, SlideImg, SlideMainHeading, SlideTopHeading, SliderWrapper } from '../../components/styledComponents';
import { Link } from 'react-router-dom';

const SlideBox = lazy(() => import('../../components/styledComponents').then(module => ({ default: module.SlideBox })));

interface IProps {
}
export const HomePageSlider: FC<IProps> = ({ }) => {
    SwiperCore.use([Autoplay]);

    return (
        <>
            <SliderWrapper>
                <Swiper
                    className="mySwiper"
                    pagination={{ clickable: true }}
                    modules={[Autoplay, Pagination]}
                    loop={true}
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                >
                    <SwiperSlide>
                        <Suspense fallback={<div className='fallback'></div>}>
                            <SlideBox bgimg='./images/home/slider-1.webp'>
                                <SlideContent>
                                    <SlideTopHeading>IT Software & Design</SlideTopHeading>
                                    <SlideMainHeading>Leading IT & SOftware</SlideMainHeading>
                                    <SlideBottmHeading>Development Company</SlideBottmHeading>
                                    <Link className='text-decoration-none' style={{ color: "inherit" }} to="/contact">
                                        <SlideButton varient='orange'>
                                            Get In Touch
                                        </SlideButton>
                                    </Link>
                                </SlideContent>
                            </SlideBox>
                        </Suspense>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Suspense fallback={<div className='fallback'></div>}>
                            <SlideBox bgimg='./images/home/slider-4.webp'>
                                <SlideContent>
                                    <SlideTopHeading>Cyber Security</SlideTopHeading>
                                    <SlideMainHeading>Compliance Management</SlideMainHeading>
                                    <SlideBottmHeading>Cybersecurity Maturation</SlideBottmHeading>
                                    <Link className='text-decoration-none' style={{ color: "inherit" }} to="/cyber-security">
                                        <SlideButton varient='orange'>
                                            Read More
                                        </SlideButton>
                                    </Link>
                                </SlideContent>
                            </SlideBox>
                        </Suspense>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Suspense fallback={<div className='fallback'></div>}>
                            <SlideBox bgimg='./images/home/slider-3.webp'>
                                <SlideContent>
                                    <SlideTopHeading>Technology simplified for all</SlideTopHeading>
                                    <SlideMainHeading>Web Experience &</SlideMainHeading>
                                    <SlideBottmHeading>innovative Technologies</SlideBottmHeading>
                                    <Link className='text-decoration-none' style={{ color: "inherit" }} to="/contact">
                                        <SlideButton>
                                            Get In Touch
                                        </SlideButton>
                                    </Link>
                                </SlideContent>
                            </SlideBox>
                        </Suspense>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Suspense fallback={<div className='fallback'></div>}>
                            <SlideBox bgimg='./images/home/slider-2.webp'>
                                <SlideContent>
                                    <SlideTopHeading>Providing the simplest solution</SlideTopHeading>
                                    <SlideMainHeading>Leading IT & SOftware</SlideMainHeading>
                                    <SlideBottmHeading>for the most complex problem</SlideBottmHeading>
                                    <Link className='text-decoration-none' style={{ color: "inherit" }} to="/contact">
                                        <SlideButton>
                                            Get In Touch
                                        </SlideButton>
                                    </Link>
                                </SlideContent>
                            </SlideBox>
                        </Suspense>
                    </SwiperSlide>
                </Swiper>
            </SliderWrapper>
        </>
    );
}
