import React, { useRef, useEffect, useState, FC } from 'react';
import CountUp from 'react-countup';
import { CounterText } from './styledComponents';

interface IProps {
    end: number;
    start: number;
    duration: number;
    delay?: number;
    suffix?: any
}

export const CounterComponent: FC<IProps> = (props: IProps) => {
    const targetRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);


    function isElementInViewport(ele: any) {
        const element = targetRef.current;
        if (element) {
            var rect = element.getBoundingClientRect();

            if (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            ) {
                setIsVisible(true);
            }
        }
    }
    document.addEventListener('scroll', () => {
        isElementInViewport(targetRef);
        // console.log('check in scroll => ', isVisible);
        // if (buttonRef.current && isVisible) {
        //     buttonRef.current.click();
        // }
    });

    return (
        <>
            {/* <CountUp start={props.start} end={props.end} duration={props.duration} delay={props.delay ? props.delay : 0}>
                {({ countUpRef, start }) => (
                    <div>
                        <span ref={countUpRef}></span>
                        <button onClick={start} ref={buttonRef}>Start</button>
                    </div>
                )}
            </CountUp> */}

            <div ref={targetRef}>
                {isVisible ?
                    <>
                        <CountUp start={props.start} end={props.end} duration={props.duration} delay={props.delay ? props.delay : 0} suffix={props.suffix ? props.suffix : ''}>
                            {({ countUpRef }) => (
                                <div>
                                    <CounterText ref={countUpRef}></CounterText>
                                </div>
                            )}
                        </CountUp>
                    </>
                    :
                    null
                }
            </div>
        </>
    );
};
