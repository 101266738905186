import React, { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BodyText, Box } from "../../../components/styledComponents";
import FSLightbox from "fslightbox-react";

interface IProps {}

export const PenetrationTestSection: FC<IProps> = ({}) => {
  const [scanReport, setScanReport] = useState<boolean>(false);
  const [detailReport, setDetailReport] = useState<boolean>(false);

  return (
    <>
      <Row>
        <Col lg={6} sm={12} className="align-self-start">
          <Box className="pt-2">
            <Box mb="10px">
              <BodyText textColor="#0a0a0a" fontSize="20px" fontWeight="700">
                Penetration Test
              </BodyText>
            </Box>
            <Box>
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="10px"
                mb="7px"
              >
                Expert Soft Solution LLC offers penetration testing
                administrations on a low volume premise, with a normal of just
                10 clients each year. Our appraisals have a 14 day least
                commitment length, with the normal commitment being a month
                long.
              </BodyText>
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="10px"
                mb="7px"
              >
                Because of the extreme focus nature of our evaluations, there is
                regularly a critical lead-in time expected for planning. We've
                planned our administrations to reproduce the exercises of a
                genuine malevolent party. We don't make any effort not to stress
                about you.
              </BodyText>
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="10px"
                mb="7px"
              >
                Expert Soft Solution LLC Security penetration evaluation will
                assist with deciding the shortcomings in networks, PC frameworks
                and applications. Dissimilar to different organizations who
                center around sequential construction system appraisals, we
                adopt a remarkable strategy.
              </BodyText>
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="10px"
                mb="7px"
              >
                Our penetration testing administrations are not simply checking
                for weaknesses and submitting a report. Basically: on the off
                chance that all you are searching for is an agenda evaluation,
                we're not the right answer for you.
              </BodyText>
            </Box>
          </Box>
        </Col>
        <Col lg={6} sm={12}>
          <div className="text-center">
            <div className="mb-3">
              <img
                className="custom-software-img border border-1 border-dark cursor-pointer"
                src="./images/services/web-security/zap-scanning-report.webp"
                alt="zap scanning report - Expert Soft Solution LLC"
                onClick={() => setScanReport(!scanReport)}
              />
              <FSLightbox
                toggler={scanReport}
                sources={[
                  "./images/services/web-security/zap-scanning-report.webp",
                ]}
              />
            </div>
            <div className="mb-1">
              <img
                className="custom-software-img border border-1 border-dark cursor-pointer"
                src="./images/services/web-security/penetration-test-2.webp"
                alt="penetration test - Expert Soft Solution LLC"
                onClick={() => setDetailReport(!detailReport)}
              />
              <FSLightbox
                toggler={detailReport}
                sources={[
                  "./images/services/web-security/penetration-test-2.webp",
                ]}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
