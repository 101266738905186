import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box } from '../../../components/styledComponents';

interface IProps {
}

export const TwoFactorAuthenticateSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row>
                <Col lg={12} sm={12} className='pt-3'>
                    <Box mb='10px'>
                        <BodyText textColor='#0a0a0a' fontSize='20px' fontWeight='700'>
                            Two-factor authentication (2FA)
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            Relying on just usernames and passwords to secure your online accounts is no longer considered safe. Data breaches occur daily and hackers are always inventing new ways to take over your accounts. Protect yourself by enabling two-factor authentication (2FA). This blocks anyone using your stolen data by verifying your identity through your device. Enable 2FA now to protect your accounts online.
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            2FA is an extra layer of security used to make sure that people trying to gain access to an online account are who they say they are. First, a user will enter their username and a password. Then, instead of immediately gaining access, they will be required to provide another piece of information. This second factor could come from one of the following categories:
                        </BodyText>
                        <ul className='ps-5'>
                            <li>
                                <Box>
                                    <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                                        <strong>Something you know:</strong> This could be a personal identification number (PIN), a password, answers to “secret questions” or a specific keystroke pattern.
                                    </BodyText>
                                </Box>
                            </li>
                            <li>
                                <Box>
                                    <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                                        <strong>Something you have:</strong> Typically, a user would have something in their possession, like a credit card, a smartphone, or a small hardware token.
                                    </BodyText>
                                </Box>
                            </li>
                            <li>
                                <Box>
                                    <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                                        <strong>Something you are:</strong> This category is a little more advanced, and might include biometric pattern of a fingerprint, an iris scan, or a voice print.
                                    </BodyText>
                                </Box>
                            </li>
                        </ul>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="15px" mb="7px">
                            With 2FA, a potential compromise of just one of these factors won’t unlock the account. So, even if your password is stolen or your phone is lost, the chances of a someone else having your second-factor information is highly unlikely. Looking at it from another angle, if a consumer uses 2FA correctly, websites and apps can be more confident of the user’s identity, and unlock the account.
                        </BodyText>
                    </Box>
                    <Box mb='10px'>
                        <BodyText textColor='#0a0a0a' fontSize='20px' fontWeight='700'>
                            Google Authenticator
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            Google Authenticator is a software-based authenticator by Google that implements two-step verification services using the Time-based One-time Password Algorithm (TOTP) and HMAC-based One-time Password algorithm (HOTP), for authenticating users of software applications.
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            When logging into a site supporting Authenticator (including Google services) or using Authenticator-supporting third-party applications such as password managers or file hosting services, Authenticator generates a six- to eight-digit one-time password which users must enter in addition to their usual login details.
                        </BodyText>
                    </Box>
                    <Box mb='10px'>
                        <BodyText textColor='#0a0a0a' fontSize='20px' fontWeight='700'>
                            Microsoft Authenticator
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            Microsoft Authenticator enables you to use two-factor verification without repeatedly receiving text messages. When you sign in to a Microsoft account, the Authenticator can provide either a code or a notification for your approval. The app also works with non-Microsoft accounts via its code generator and is available for Android and iOS.
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            The one-time access code can be texted to a phone number associated with your account, but the Google Authenticator app can provide those codes on your smartphone instead. These codes ensure no one else has access to your accounts, as a third-party would not only need your password but also access to your phone to log in — something remote hackers are unlikely to get their hands on.
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            Google Authenticator works across Google’s services, as well as a wide variety of other online accounts, including Slack.
                        </BodyText>
                    </Box>
                    <Box mb='10px'>
                        <BodyText textColor='#0a0a0a' fontSize='20px' fontWeight='700'>
                            Azure Web Application Firewall (WAF)
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            Azure Web Application Firewall is a cloud-native service that protects web apps from common web-hacking techniques such as SQL injection and security vulnerabilities such as cross-site scripting. Deploy the service in minutes to get complete visibility into your environment and block malicious attacks.
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            Easily deploy Azure Web Application Firewall security with no additional software agent required. Centrally define and customize rules to meet your security requirements, then apply them to protect all your web apps.
                        </BodyText>
                    </Box>
                </Col>
            </Row>
        </>
    );
}