import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SparkyApp } from '../../../../DataJson';
import { PortfolioBoxCard } from '../../../../components/portfolioBoxCard';


interface IProps {
}

export const CardMainSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row className='pt-4 mb-5'>
                {SparkyApp.map((item, index) => {
                    return <>
                        <Col lg={3} sm="12" className='mb-4' key={`sparkyApp-${index + 1}`}>
                            <PortfolioBoxCard
                                image={item.image}
                                islightbox={true}
                            />

                        </Col>
                    </>
                })}
            </Row>
        </>
    );
}
