import React, { FC, useRef, useEffect, useState } from 'react';
import { Box, CounterIcon, CounterListItem, CounterSectionWrapper, CounterTitle, ProjectSectionWrapper } from '../../components/styledComponents';
import { Col, Container, Row } from 'react-bootstrap';
import { CounterComponent } from '../../components/counterComponent';

interface IProps {
}

export const AutoIncrementSection: FC<IProps> = ({ }) => {

    return (
        <>
            <CounterSectionWrapper>
                <Container>
                    <Row>
                        <Col lg={3} md={6}>
                            <CounterListItem className='mb-30px-md mb-30px-sm'>
                                <CounterIcon>
                                    <img src='./images/home/business.webp' alt='image' loading="lazy" />
                                </CounterIcon>
                                <Box>
                                    <CounterComponent start={0} end={100} duration={1} suffix='+' />
                                    <CounterTitle>HAPPY CLIENTS</CounterTitle>
                                </Box>
                            </CounterListItem>
                        </Col>
                        <Col lg={3} md={6}>
                            <CounterListItem className='mb-30px-md mb-30px-sm'>
                                <CounterIcon>
                                    <img src='./images/home/countdown.webp' alt='image' loading="lazy" />
                                </CounterIcon>
                                <Box>
                                    <CounterComponent start={0} end={10} duration={1} suffix='+' />
                                    <CounterTitle>YEARS EXPERIENCE</CounterTitle>
                                </Box>
                            </CounterListItem>
                        </Col>
                        <Col lg={3} md={6}>
                            <CounterListItem className='mb-30px-sm'>
                                <CounterIcon>
                                    <img src='./images/home/launch.webp' alt='image' loading="lazy" />
                                </CounterIcon>
                                <Box>
                                    <CounterComponent start={0} end={100} duration={1} suffix='+' />
                                    <CounterTitle>PROJECTS</CounterTitle>
                                </Box>
                            </CounterListItem>
                        </Col>
                        <Col lg={3} md={6}>
                            <CounterListItem>
                                <CounterIcon>
                                    <img src='./images/home/web-development.webp' alt='image' loading="lazy" />
                                </CounterIcon>
                                <Box>
                                    <CounterComponent start={0} end={25} duration={1} suffix='+' />
                                    <CounterTitle>EXPERT ENGINEERS</CounterTitle>
                                </Box>
                            </CounterListItem>
                        </Col>
                    </Row>
                </Container>
            </CounterSectionWrapper>
        </>
    );
}
