import React, { FC } from 'react';
import { Box, FooterTitle, ServicesMenu } from '../../components/styledComponents';
import { NavLink } from 'react-router-dom';
import { footerMenu } from '../../commonJSON';

interface IProps {
}

export const FooterMenu: FC<IProps> = ({ }) => {
    return (
        <>
            <Box>
                <FooterTitle>IT Services</FooterTitle>
            </Box>
            <Box>
                <ServicesMenu>
                    {footerMenu.map((item: IMenuItems, index: number) => {
                        return <>
                            <li key={`${item.label}-${index + 1}`}>
                                <NavLink to={item.path ? item.path : 'javascript:;'} title={item.label}>{item.label}</NavLink>
                            </li>
                        </>
                    })}
                </ServicesMenu>
            </Box>
        </>
    );
}
