import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box, DisplayStart } from '../../../components/styledComponents';
import { FaCheckCircle } from 'react-icons/fa';
import { CloudListingData } from '../../../DataJson';

interface IProps {
}

export const IconBoxSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row className='pt-3 pb-1'>
                {CloudListingData.map((item: ICustomServiceData, index: number) => {
                    return (
                        <>
                            <Col lg={6} sm="12" key={`ICloudListing-${index + 1}`}>
                                <DisplayStart className='align-items-start' mb="20px">
                                    <Box mr="12px" mt="4px">
                                        <FaCheckCircle size={25} className='color-blue' />
                                    </Box>
                                    <Box>
                                        <BodyText textColor='#454545' fontSize='20px' fontWeight='700' mb="5px">
                                            {item.title}
                                        </BodyText>
                                        <BodyText textColor='#454545' fontSize='20px' fontWeight='500'>
                                            {item.description}
                                        </BodyText>
                                    </Box>
                                </DisplayStart>
                            </Col>
                        </>
                    )
                })}
            </Row>
        </>
    );
}