import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import {
  AboutSectionShapeImg,
  BodyText,
  Box,
  DotLine,
  HeadingTitle,
  TesxtRightWrapper,
  TitleDescription,
} from "../../components/styledComponents";

interface IProps {}

export const WhoWeAreSection: FC<IProps> = ({}) => {
  return (
    <>
      <Row>
        <Col lg={6}>
          <AboutSectionShapeImg className="aboutUs-animation-shape-image">
            <div className="pattern about-patren">
              <img
                decoding="async"
                className="scale2"
                src="./images/home/about-effect-1.webp"
                alt="image"
              />
            </div>
            <div className="middle-image">
              <img
                className=" ls-is-cached lazyloaded"
                src="./images/about/who-we-are.webp"
                alt="image"
              />
            </div>
            <div className="shape">
              <img
                decoding="async"
                className="dance"
                src="./images/home/dotted-3.webp"
                alt="image"
              />
            </div>
          </AboutSectionShapeImg>
        </Col>
        <Col lg={6} className="align-self-start">
          <TesxtRightWrapper>
            <Box mb="20px">
              <BodyText
                textAlign="left"
                textColor="#e47226"
                fontWeight="400"
                fontSize="14px"
                className="text-uppercase letter-space-1px"
                mb="10px"
              >
                About Us
              </BodyText>
              <HeadingTitle>Who We are</HeadingTitle>
              <DotLine />
            </Box>
            <Box mt="15px">
              <TitleDescription>
                Over 10+ years working in IT services developing software
                applications and mobile apps for clients all over the world.
              </TitleDescription>
            </Box>
            <Box mb="45px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="400"
                mt="15px"
                mb="10px"
              >
                <span className="fw-bolder">Expert Soft Solution LLC</span> is a
                leading Software Provider Company that takes pride in its
                innovative approach to IT solutions and services; catering to a
                substantial and diversified client base.
              </BodyText>
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="400"
                mt="15px"
                mb="10px"
              >
                <span className="fw-bolder">Expert Soft Solution LLC</span> is a
                global IT solutions, consultancy and services provider
                specialized in affordable business and technology solutions.
                <span className="fw-bolder">
                  {" "}
                  Expert Soft Solution LLC,
                </span>{" "}
                inaugurated as an independent organization in fall 2009, emerged
                as a leading provider of customized software solutions. Since
                its inception,{" "}
                <span className="fw-bolder">Expert Soft Solution LLC</span> has
                successfully traded into numerous other industries and
                positioned itself as a global software solutions provider across
                a number of industries.
              </BodyText>
            </Box>
          </TesxtRightWrapper>
        </Col>
      </Row>
    </>
  );
};
