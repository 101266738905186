import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";


export const DisplayStarting = styled.div<{
    width?: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
    verticalAlign?: string,
    flexDirection?: string
}>`
width: ${(props) => props.width ? props.width : 'auto'};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
margin-top:${props => props.mt ? props.mt : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
display:flex;
flex-direction:${props => props.flexDirection ? props.flexDirection : 'row'};
align-items:${props => props.verticalAlign ? props.verticalAlign : 'center'};
justify-content:start;
`

export const BannerSection = styled.div<{
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
    cursor?: string,
    width?: string,
}>`
display:block;
margin-top:${(props) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : '150px'};
padding-bottom:${props => props.pb ? props.pb : '150px'};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
cursor:${props => props.cursor ? props.cursor : null};
width:${props => props.width ? props.width : null};
`

export const BannerImg = styled.div<{
    bgimg: string,
}>`
background-image:url(${(props) => props.bgimg});
width:100%;
height:356px;
background-size: cover;
background-position: center top;
background-repeat: no-repeat;
min-width: 100%;
min-height: 100%;
@media (max-width:991px){
    height:350px;
}
@media (max-width:524px){
    height:332px;
}
`

export const FooterTopBanner = styled.div<{
    bgimg: string,
}>`
background-image:url(${(props) => props.bgimg});
width:100%;
height:233px;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
min-width: 100%;
min-height: 100%;
padding: 40px 0 50px;
transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
@media (max-width:767px) and (min-width:621px){
    height:300px;
}
@media(max-width:450px){
    height:255px;
}
@media(max-width:620px){
    height:260px;
}
`

export const BannerTitle = styled.div<{
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
    cursor?: string,
    width?: string,
}>`
display:block;
color:#fff;
word-break: break-word;
text-shadow: 1px 1px 2px rgba(0,0,0,.3);
font-size: 48px;
font-weight: 700;
margin: 0 auto;
line-height: 56px;
text-align: center;
height:100%;
max-width: 850px;
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
cursor:${props => props.cursor ? props.cursor : null};
width:${props => props.width ? props.width : null};
@media (max-width:991px){
    font-size: 40px !important;
    line-height: 50px !important;
}
@media (max-width:524px){
    font-size: 24px!important;
    line-height: 32px!important;
}
`

export const KeyDifferSection = styled.div<{
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}>`
display:block;
margin-top:${(props) => props.mt ? props.mt : '50px'};
margin-bottom:${props => props.mb ? props.mb : '50px'};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`

export const KeyBox = styled.div`
padding: 20px;
background-color: #e6eeff;
box-sizing: border-box;
height: 262px;
transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
&:hover{
    color:#e47226;
    }
`

export const KeyInnerBox = styled.div`
text-align: center;
transition: .4s;
overflow: hidden;
`

export const BannerTitleWrpper = styled.div`
@media (min-width:991px) {
    width: 65%;
    margin: 0 auto;
    height: 100%;
}
`

export const CheckFeatureSection = styled.div`
background-color: #f1f6fc;
transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
padding: 55px 0;
`

export const BorderLineVertical = styled.div`
background: #E47226;
width: 4px;
height: 40px;
z-index: 1;
margin-left: 0;
left: 0;
top: 0;
`
export const BorderLinehorizontal = styled.div`
background: #E47226;
width: 66px;
height: 4px;
z-index: 1;
margin-left: 0;
left: 0;
top: 0;
`

export const PortfolioBox = styled.div`
background-color: #fff;
border-radius: 15px;
border-style: solid;
border-width: 0;
box-shadow: 1px 1px 15px 1px rgba(0,0,0,.06);
transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
padding: 20px;
`

export const PortfolioImg = styled.img<{
    imgheight?: string,
    imgWidth?: string,
}>`
height:${(props) => props.imgheight ? props.imgheight : "350px"};
width:${props => props.imgWidth ? props.imgWidth : "100%"};
border-style: solid;
border-width: 0;
border-radius: 15px;
box-shadow: 1px 1px 15px 1px rgba(0,0,0,.08);
vertical-align: middle;
display: inline-block;
object-fit:cover;
object-position:top;
`
export const PortfolioTitle = styled.div<{
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    textalign?: string,
}>`
margin-top:${(props) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
text-align:${props => props.textalign ? props.textalign : 'left'};
color: #000;
font-weight: 700;
font-size: 28px;
`
export const PortfolioSubTitle = styled.div`
color: #000;
font-weight: 600;
font-size: 18px;
`