import React, { FC } from "react";
import { PageBanner } from "../../../components/pageBanner";
import { Container } from "react-bootstrap";
import {
  BodyText,
  Box,
  DisplayCenter,
  DotLine,
  HeadingTitle,
} from "../../../components/styledComponents";
import { ImgTextSection } from "./imgTextSection";
import { FooterTop } from "../../../components/footerTop";
import { BottomTextSection } from "./bottomTextSection";

interface IProps {}

export const OutsourcingToPakistan: FC<IProps> = ({}) => {
  return (
    <>
      <PageBanner
        bannerImg="./images/company/company_banner.webp"
        pageTitle="Expert Soft Solution LLC / Outsourcing To Pakistan"
      />
      <ImgTextSection />
      <BottomTextSection />
      <FooterTop
        subTitle="Plan to Start a Project"
        title="Our Experts Ready to Help You"
        btnText="Contact Us"
      />
    </>
  );
};
