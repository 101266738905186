import React, { FC } from 'react';
import { DisplayCenter, HeadingTitle, HeadingTopLabel, WorkProcessImg, WorkProcessWrapper } from '../../../components/styledComponents';

interface IProps {
}

export const WorkProcess: FC<IProps> = ({ }) => {
    return (
        <>
            <WorkProcessWrapper>
                <DisplayCenter mb="30px" flexDirection='column'>
                    <HeadingTopLabel bgColor='#1273EB4A' textColor='#fff'>CONTACT</HeadingTopLabel>
                    <HeadingTitle textAlign='center' textColor='#fff'>ESS Process of work</HeadingTitle>
                </DisplayCenter>
                <DisplayCenter mb="30px" flexDirection='column'>
                    <WorkProcessImg src='./images/company/ourProcess/our-process-of-work.webp' />
                </DisplayCenter>
            </WorkProcessWrapper>
        </>
    );
}
