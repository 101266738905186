import React, { FC } from 'react';
import { Box, HeaderIconBox, HeaderIconTextBox, HeaderQuoteBox, MainLayoutHeaderTopWrapper, MainLayoutLogoWrapper } from '../../../components/styledComponents';
import { Col, Container, Row } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaRegClock, FaRegEnvelope } from "react-icons/fa";
import { TbPhoneCall } from "react-icons/tb";
import { Link } from 'react-router-dom';

interface IProps {
}

export const HeaderTop: FC<IProps> = ({ }) => {
    return (
        <>
            <MainLayoutHeaderTopWrapper>
                <Container fluid className='px-5 container-lg-p'>
                    <Row>
                        <Col xl={7} lg={6}>
                            <MainLayoutLogoWrapper>
                                <Box className='logo-area'>
                                    <Link to="/">
                                        <img src="./images/ESS-Logo-Final.webp" alt="ESS Logo" />
                                    </Link>
                                </Box>
                            </MainLayoutLogoWrapper>
                        </Col>
                        <Col xl={5} lg={6} className='align-self-center'>
                            <HeaderQuoteBox>
                                <HeaderIconBox>
                                    <Box className="icon-box">
                                        <img src='../images/mail.png' alt='email' />
                                    </Box>
                                    <HeaderIconTextBox>
                                        <Box className='info-title'>
                                            Email
                                        </Box>
                                        <Box className='info-des'>
                                            <a className='text-decoration-none text-dark' href='mailto:info@expertsoftsolution.com'>
                                                info@expertsoftsolution.com
                                            </a>
                                        </Box>
                                    </HeaderIconTextBox>
                                </HeaderIconBox>
                                <Box className='borderLeft'></Box>
                                <HeaderIconBox>
                                    <Box className="icon-box">
                                        <img src='../images/call.png' alt='phone' />
                                    </Box>
                                    <HeaderIconTextBox>
                                        <Box className='info-title'>
                                            Phone
                                        </Box>
                                        <Box className='info-des'>
                                            <a className='text-decoration-none text-dark' href='tel:+92-301-4728-930'>
                                                +92-301-4728-930
                                            </a>
                                        </Box>
                                    </HeaderIconTextBox>
                                </HeaderIconBox>
                            </HeaderQuoteBox>
                        </Col>
                    </Row>
                </Container>
            </MainLayoutHeaderTopWrapper>
        </>
    );
}
