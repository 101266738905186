import React, { FC, lazy, Suspense } from 'react';
import { DisplayStart, GetAQuoteFormBox, GetAQuoteSectionWrapper, HeadingTitle, HeadingTopLabel } from '../../components/styledComponents';
import { Col, Container, Row } from 'react-bootstrap';
import { GetAQuoteForm } from '../../components/getAQuoteForm';

const GetAQuoteImg = lazy(() => import('../../components/styledComponents').then(module => ({ default: module.GetAQuoteImg })));

interface IProps {
}

export const GetAQuote: FC<IProps> = ({ }) => {
    return (
        <>
            <Container fluid className='px-5 container-lg-p'>
                <GetAQuoteSectionWrapper>
                    <Row className="align-items-stretch h-100">
                        <Col lg={6}>
                            <Suspense>
                                <GetAQuoteImg />
                            </Suspense>
                        </Col>
                        <Col lg={6}>
                            <GetAQuoteFormBox>
                                <DisplayStart mb='20px' flexDirection='column' verticalAlign='start'>
                                    <HeadingTopLabel>QUERY</HeadingTopLabel>
                                    <HeadingTitle textAlign='center'>Get A Quote</HeadingTitle>
                                </DisplayStart>
                                <GetAQuoteForm />
                            </GetAQuoteFormBox>
                        </Col>
                    </Row>
                </GetAQuoteSectionWrapper>
            </Container>
        </>
    );
}
