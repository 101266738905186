import React, { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import {
  BodyText,
  Box,
  HeadingTitle,
} from "../../../components/styledComponents";

interface IProps {}

export const CurrentOpenings: FC<IProps> = ({}) => {
  return (
    <>
      <Container>
        <Row>
          <Col lg={6}>
            <Box mb="20px">
              <BodyText
                textAlign="left"
                lineHeight="28px"
                textColor="#e47226"
                fontWeight="400"
                fontSize="14px"
                className="text-uppercase letter-space-1px"
                mb="10px"
              >
                CURRENT OPENINGS
              </BodyText>
              <HeadingTitle fontsize="20px">Web Developer</HeadingTitle>
            </Box>
            <Box mb="20px">
              <BodyText
                textColor="#000"
                fontSize="19px"
                fontWeight="500"
                mt="15px"
                mb="10px"
              >
                We are looking for ASP.NET Developers. The candidate should be
                involved in ASP.NET website development (using 3 tier
                architecture) life cycle starting from requirement analysis to
                testing. He should have excellent knowledge of Asp.net, C#,
                Entity Framework, Linq, HTML, JavaScript, Jquery, CSS, HTML5 +
                XML, MS SQL, Database Designing, Rad Telerik controls experience
                is a plus point. The candidate should be able to work in a team
                or should be able to handle projects independently.
              </BodyText>
            </Box>
            <Box mb="20px">
              <HeadingTitle fontsize="20px">Required Skills</HeadingTitle>
            </Box>
            <Box mb="20px">
              <BodyText
                textColor="#000"
                fontSize="19px"
                fontWeight="500"
                mt="15px"
                mb="10px"
              >
                Asp.net | MVC | MS SQL Database | Entity Framework| Jquery
              </BodyText>
            </Box>
            <Box mb="20px">
              <HeadingTitle fontsize="20px">Tools</HeadingTitle>
            </Box>
            <Box mb="20px">
              <BodyText
                textColor="#000"
                fontSize="19px"
                fontWeight="500"
                mt="15px"
                mb="10px"
              >
                MS Visual Studio| MS SQL Management Studio
              </BodyText>
            </Box>
          </Col>
          <Col lg={6}>
            <Box mb="20px">
              <BodyText
                textAlign="left"
                lineHeight="28px"
                textColor="#e47226"
                fontWeight="400"
                fontSize="14px"
                className="text-uppercase letter-space-1px"
                mb="10px"
              >
                CURRENT OPENINGS
              </BodyText>
              <HeadingTitle fontsize="20px">Quality Assurance</HeadingTitle>
            </Box>
            <Box mb="20px">
              <BodyText
                textColor="#000"
                fontSize="19px"
                fontWeight="500"
                mt="15px"
                mb="10px"
              >
                We are looking QA person who have thorough understanding and
                strong Software Quality Assurance concepts. Your duty is to
                create test cases of all components of product such as verifying
                functionality, data integrity, security, overall usability of
                software applications, regression testing, end-to-end testing,
                performance, and load testing and to ensure that software meets
                or exceeds specified standards and end-user requirements.
              </BodyText>
            </Box>
            <Box mb="20px">
              <HeadingTitle fontsize="20px">Required Skills</HeadingTitle>
            </Box>
            <Box mb="20px">
              <ul className="req-skill-list">
                <li>
                  Should have a test to break attitude and should be able to
                  design positive &amp; negative test scenarios based on the
                  validation on controls.
                </li>
                <li>
                  Ability to manage multiple projects at one time, often with
                  changing requirements as scrum team needs may dictate
                </li>
                <li>
                  Must be detail oriented, reliable and have the ability to work
                  both in a team environment and alone with minimal direction
                  and supervision.
                </li>
                <li>
                  Document software defects, using a bug tracking system, and
                  report defects to software developers. Thorough knowledge of
                  structured test methods and processes.
                </li>
                <li>
                  Knowledge of agile scrum development life cycles and how they
                  pertain to Quality Assurance needs.
                </li>
                <li>EXCELLENT, demonstrable knowledge of UI/UX concepts.</li>
              </ul>
            </Box>
          </Col>
          <Col lg={12} className="mt-5">
            <Box mb="20px">
              <HeadingTitle fontsize="20px" className="heading-border">
                How to Apply
              </HeadingTitle>
            </Box>
            <Box mb="20px">
              <BodyText
                textColor="#000"
                fontSize="19px"
                fontWeight="500"
                mt="15px"
                mb="10px"
              >
                If you are interested in exploring a career with Expert Soft
                Solution LLC, we would like to hear from you. Please take a look
                at our list of current openings and apply to the ones that
                interest you and match your expertise. If you don’t see a match,
                you are still welcome to send your resume to Alternate Text as
                we actively search our CV bank for new job opportunities.
              </BodyText>
            </Box>
          </Col>
        </Row>
      </Container>
    </>
  );
};
