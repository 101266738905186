import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaRegClock, FaRegEnvelope } from "react-icons/fa";
import { TbPhoneCall } from "react-icons/tb";


interface IProps {
}
export const HeaderTop: FC<IProps> = ({ }) => {
    return (
        <>
            <div className="toolbar-area">
                <Container fluid className='px-5'>
                    <Row>
                        <Col lg={8}>
                            <div className="toolbar-contact">
                                <ul className="rs-contact-info">
                                    <li className="rs-contact-email">
                                        <FaRegEnvelope fontSize={15} className='me-2' />
                                        <a href="mailto:info@expertsoftsolution.com">info@expertsoftsolution.com</a>
                                    </li>
                                    <li className="rs-contact-phone">
                                        <TbPhoneCall fontSize={16} className='me-2' />
                                        <a href="tel:+92-301-4728-930"> +92-301-4728-930</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="toolbar-sl-share">
                                <ul className="clearfix">
                                    <li className="opening">
                                        <span>
                                            <FaRegClock fontSize={15} className='me-2' />
                                            8:00 am - 8:00 pm
                                        </span>
                                    </li>
                                    <li> <a href="https://www.facebook.com/ExpertSoftSolution/" target="_blank"><FaFacebookF fontSize={15} /></a> </li>
                                    <li> <a href="https://twitter.com/ExpertSoftSolut " target="_blank"><FaTwitter fontSize={15} /></a> </li>
                                    <li> <a href="https://www.linkedin.com/company/expert-soft-solution/ " target="_blank"><FaLinkedinIn fontSize={15} /></a> </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
