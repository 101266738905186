import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AboutSectionShapeImg, BodyText, Box, DisplayCenter, DisplayEnd, DisplayStart, DotLine, HeadingTitle, TesxtRightWrapper, TitleDescription } from '../../../components/styledComponents';
import { FaCheckCircle } from 'react-icons/fa';
import { OTPlist } from '../../../commonJSON';

interface IProps {
}

export const ImgTextSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Container>
                <Box mt='40px'>
                    <Row>
                        <Col lg={6} >
                            <Box mb='20px'>
                                <BodyText textAlign='left' lineHeight='28px' textColor='#e47226' fontWeight='400' fontSize='14px' className='text-uppercase letter-space-1px' mb="10px">CAREER</BodyText>
                                <HeadingTitle>Outsourcing to Pakistan</HeadingTitle>
                                <DotLine />
                            </Box>
                            <Box mb='30px'>
                                <BodyText fontSize='18px' fontWeight='500' mt="15px" mb="10px">
                                    To be honest, despite so much problems and concerning issues, Pakistan is still a fertile ground of talented professionals,
                                    particularly in software development, website designing, search engine optimization and IT related services.
                                </BodyText>
                            </Box>
                            <Box mb='30px'>
                                <HeadingTitle fontsize='20px'>IT Outsourcing</HeadingTitle>
                            </Box>
                            <Box mb='30px'>
                                <BodyText fontSize='18px' fontWeight='500' mt="15px" mb="10px">
                                    Information technology has been enabling millions of businesses around the world get smarter and more profitable. We welcome all businesses,
                                    small and large to consider IT outsourcing as a opportunity to reduce costs and increase efficiency. We ensure quality work, dependable support
                                    and off course very attractive pricing for all kinds of IT services.
                                </BodyText>
                            </Box>
                        </Col>
                        <Col lg={6} className='order-col-sm-2'>
                            <DisplayCenter>
                                <div className="middle-image">
                                    <img className="ls-is-cached lazyloaded img-fluid" src="./images/company/ourProcess/our_process.webp" alt="image" />
                                </div>
                            </DisplayCenter>
                        </Col>
                    </Row>
                </Box>
            </Container>
        </>
    );
}