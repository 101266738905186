import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DisplayCenter, HeadingTitle, HeadingTopLabel, Patrenimg, SolutionIconBox, SolutionText, SolutionTextBox, SolutionTitle, SolutionWrapper } from '../../components/styledComponents';
import { solutionData } from '../../commonJSON';

interface IProps {
}

export const OurSolutionsSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row>
                <Col lg={3}></Col>
                <Col lg={6}>
                    <DisplayCenter flexDirection='column'>
                        <HeadingTopLabel>SOLUTIONS</HeadingTopLabel>
                        <HeadingTitle textAlign='center'>Over 10+ Years & Our Solutions Includes</HeadingTitle>
                    </DisplayCenter>
                </Col>
                <Col lg={3}></Col>
            </Row>
            <Row>
                {solutionData.map((item: ISolutionData, index: number) => {
                    return <>
                        <Col lg={3} md={6} key={`${item.title}-${index + 1}`}>
                            <SolutionWrapper>
                                <Patrenimg className="scale-up-center" src="./images/home/shape3.webp" alt='patren' loading="lazy" />
                                <SolutionIconBox>
                                    <img src={item.icon} alt='solution1' />
                                </SolutionIconBox>
                                <SolutionTextBox>
                                    <SolutionTitle>
                                        {item.title}
                                    </SolutionTitle>
                                    <SolutionText>
                                        {item.text}
                                    </SolutionText>
                                </SolutionTextBox>
                            </SolutionWrapper>
                        </Col>
                    </>
                })}
            </Row>
        </>
    );
}
