import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BodyText,
  Box,
  DotLine,
  HeadingTitle,
} from "../../../components/styledComponents";

interface IProps {}

export const MainSection: FC<IProps> = ({}) => {
  return (
    <>
      <Row>
        <Col lg={6} sm={12} className="align-self-start">
          <Box className="pt-2">
            <Box mb="20px">
              <HeadingTitle> Cloud and DevOps </HeadingTitle>
              <DotLine />
            </Box>
            <Box mt="20px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                Our DevOps Team’s goal is to deliver reliable products, build
                and maintain highly available infrastructure and get ideas to
                the market quickly.
              </BodyText>
            </Box>
            <Box mb="28px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                We leverage Cloud-based solutions to bring your digital product
                from idea to release.
              </BodyText>
            </Box>
            <Box mb="28px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                The Expert Soft Solution LLC DevOps practices involve deploying
                top of the line solutions to make sure our approach to
                infrastructure is maintainable, scalable and consistent between
                projects.
              </BodyText>
            </Box>
          </Box>
        </Col>
        <Col lg={6} sm={12}>
          <div className="mb-1">
            <img
              className="custom-software-img"
              src="./images/services/cloud-devOps/cloud-and-devOps.webp"
              alt="cloud and devOps - Expert Soft Solution LLC"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
