import React, { FC, useState } from 'react';
import { HeaderTop } from './headerTop';
import { Box, DisplayBetween, HeaderWrapper, MainLayoutMenuWrapper, MobileMenuWrapper } from '../../../components/styledComponents';
import { Menu } from '../../../components/menu';
import { Col, Container, Row } from 'react-bootstrap';
import { MobileMenu } from '../../mobileMenu';

interface IProps {
}

export const Header: FC<IProps> = ({ }) => {
    const [isTop, setisTop] = useState<boolean>(false);

    document.addEventListener('scroll', () => {
        if (window.scrollY >= 150) {
            setisTop(true);
        } else {
            setisTop(false);
        }

    });

    return (
        <>
            <HeaderTop />
            {isTop ?
                <>
                    {/* Sticky Header */}
                    <HeaderWrapper className={`${isTop ? 'menu-sticky' : ''}`}>
                        <Container fluid className='px-5 container-lg-p'>
                            <Row className='position-relative'>
                                <Col lg={3} className='text-start'>
                                    <img src="./images/ESS-Logo-Final.webp" alt="ESS Logo" />
                                </Col>
                                <Col lg={9}>
                                    <Box className='text-start home-menu'>
                                        <Menu isDesktop={true} styleClass='sticky' />
                                    </Box>
                                    <Box className='text-end mobile-menu'>
                                        <MobileMenuWrapper>
                                            <MobileMenu isSticky={true} />
                                        </MobileMenuWrapper>
                                    </Box>
                                </Col>
                            </Row>
                        </Container>
                    </HeaderWrapper>
                </>
                :
                <>
                    {/* Main Layout Header */}
                    <MainLayoutMenuWrapper>
                        <Container fluid className='px-5 container-lg-p'>
                            <Row>
                                <Col lg={10} className='ps-0'>
                                    <Box className='text-start home-menu'>
                                        <Menu isDesktop={true} isMainLyaout={true} />
                                    </Box>
                                </Col>
                                <Col lg={2}>
                                    <Box className='text-end mobile-menu'>
                                        <DisplayBetween pb='5px' pt='5px'>
                                            <Box>
                                                <img src='./images/essLogoWhite.webp' alt='logo' width="130" />
                                            </Box>
                                            <Box pb='5px' pt='5px'>
                                                <MobileMenu />
                                            </Box>
                                        </DisplayBetween>
                                    </Box>
                                </Col>
                            </Row>
                        </Container>
                    </MainLayoutMenuWrapper>
                </>
            }

        </>
    );
}
