import React, { FC } from "react";
import { PageBanner } from "../../../components/pageBanner";
import { Container } from "react-bootstrap";
import {
  BodyText,
  Box,
  CareerPageWrapper,
  DisplayCenter,
  DotLine,
  HeadingTitle,
} from "../../../components/styledComponents";
import { ImgTextSection } from "./imgTextSection";
import { CurrentOpenings } from "./currentOpenings";
import { ContactUsForm } from "./contactUsForm";
import { FooterTop } from "../../../components/footerTop";

interface IProps {}

export const Career: FC<IProps> = ({}) => {
  return (
    <>
      <PageBanner
        bannerImg="./images/company/career/career_banner.webp"
        pageTitle="Expert Soft Solution LLC / Career"
      />
      <CareerPageWrapper>
        <ImgTextSection />
        <CurrentOpenings />
      </CareerPageWrapper>
      <ContactUsForm />
      <FooterTop
        subTitle="Plan to Start a Project"
        title="Our Experts Ready to Help You"
        btnText="Contact Us"
      />
    </>
  );
};
