import React, { FC } from 'react';
import { BodyText, Box, ButtonGradient, DisplayCenter, HeadingTitle } from './styledComponents';
import { BannerTitle, BannerImg, FooterTopBanner } from './styledComponents2';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


interface IProps {
    subTitle?: string,
    title?: string,
    btnText?: string,
}

export const FooterTop: FC<IProps> = (props: IProps) => {

    return (
        <>
            <Box>
                <FooterTopBanner bgimg="./images/services/services-contactUs-banner-sec.webp">
                    <Container className=''>
                        <Row className='align-items-center'>
                            <Col md={8}>
                                <Box mb='20px' mt="25px !important">
                                    <BodyText textAlign='left' textColor='#fff' fontWeight='500' fontSize='14px' className='text-uppercase letter-space-1px' mb="10px"> {props.subTitle} </BodyText>
                                    <HeadingTitle textColor='#fff'> {props.title} </HeadingTitle>
                                </Box>
                            </Col>
                            <Col md={4}>
                                <Box className='text-lg-end text-md-end'>
                                    <Link className='text-decoration-none text-white' to="/contact">
                                        <ButtonGradient className='text-capitalize'>
                                            {props.btnText}
                                        </ButtonGradient>
                                    </Link>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </FooterTopBanner>
            </Box>
        </>
    );
}
