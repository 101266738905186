import React, { FC } from "react";
import { PageBanner } from "../../../components/pageBanner";
import { Container } from "react-bootstrap";
import {
  BodyText,
  Box,
  DisplayCenter,
  DotLine,
  HeadingTitle,
} from "../../../components/styledComponents";
import { ImgTextSection } from "./imgTextSection";
import { WorkProcess } from "./workProcess";

interface IProps {}

export const OurProcess: FC<IProps> = ({}) => {
  return (
    <>
      <PageBanner
        bannerImg="./images/company/company_banner.webp"
        pageTitle="Expert Soft Solution LLC / Our Process"
      />
      <ImgTextSection />
      <WorkProcess />
    </>
  );
};
