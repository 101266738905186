import React, { FC } from 'react';
import { Box, DisplayCenter, FlipBox, FlipBoxBack, FlipBoxBackButton, FlipBoxBackDescription, FlipBoxBackTitle, FlipBoxContent, FlipBoxFronTitle, FlipBoxFront, FlipBoxFrontDescription, FlipBoxFrontIcon, FlipBoxInner, HeadingTitle, HeadingTopLabel, ServiceBox, ServiceSectionShapeImg, ServicesShapeWrapper, ServicesTitleWrapper, ShapeTiltBottom, TitleDescription } from '../../components/styledComponents';
import { Col, Container, Row } from 'react-bootstrap';
import { serviceData } from '../../commonJSON';
import { Link } from 'react-router-dom';




interface IProps {
}

export const ServicesSection: FC<IProps> = ({ }) => {
    return (
        <>
            <ServicesShapeWrapper>
                <ShapeTiltBottom>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="shape-fill" d="M738,99l262-93V0H0v5.6L738,99z"></path>
                    </svg>
                </ShapeTiltBottom>
            </ServicesShapeWrapper>
            <ServicesTitleWrapper>
                <Box mb="30px" className='service-heading-responsive'>
                    <Row>
                        <Col lg={3}></Col>
                        <Col lg={6}>
                            <DisplayCenter flexDirection='column'>
                                <HeadingTopLabel>SERVICES</HeadingTopLabel>
                                <HeadingTitle textAlign='center'>We Are Offering All Kinds of IT Solutions Services</HeadingTitle>
                            </DisplayCenter>
                        </Col>
                        <Col lg={3}></Col>
                    </Row>
                </Box>
                <Container fluid className='px-5 container-lg-p'>
                    <Row>
                        {serviceData.map((item: IServiceData, index: number) => {
                            return <>
                                <Col lg={3} md={6} key={`service-${index + 1}`}>
                                    <ServiceBox>
                                        <FlipBox>
                                            <FlipBoxInner>
                                                <FlipBoxFront className="front-part">
                                                    <FlipBoxContent>
                                                        <FlipBoxFrontIcon>
                                                            <span>
                                                                <img src={item.icon} alt={`service-${index + 1}`} loading="lazy" />
                                                            </span>
                                                        </FlipBoxFrontIcon>
                                                        <FlipBoxFronTitle>
                                                            <h3>{item.title}</h3>
                                                        </FlipBoxFronTitle>
                                                        <FlipBoxFrontDescription>
                                                            <p>{item.description}</p>
                                                        </FlipBoxFrontDescription>
                                                    </FlipBoxContent>
                                                </FlipBoxFront>
                                                <FlipBoxBack className="back-part">
                                                    <FlipBoxContent>
                                                        <FlipBoxBackTitle>
                                                            <h3><Link to={item.link} title={item.title} className='title-link'>{item.title}</Link></h3>
                                                        </FlipBoxBackTitle>
                                                        <FlipBoxBackDescription>
                                                            <p>{item.description}</p>
                                                        </FlipBoxBackDescription>
                                                        <FlipBoxBackButton>
                                                            <Link to={item.link} title={item.title} className='back-btn'>View More</Link>
                                                        </FlipBoxBackButton>
                                                    </FlipBoxContent>
                                                </FlipBoxBack>
                                            </FlipBoxInner>
                                        </FlipBox>
                                    </ServiceBox>
                                </Col>
                            </>
                        })}

                    </Row>
                </Container>
                <Row>
                    <Col lg={6}>
                        <ServiceSectionShapeImg className='services-animation-shape-image'>
                            <div className="pattern">
                                <img src="./images/home/patren.webp" alt="image" loading="lazy" className="scale2" />
                            </div>
                        </ServiceSectionShapeImg>
                    </Col>
                    <Col lg={6}></Col>
                </Row>
            </ServicesTitleWrapper>
        </>
    );
}
