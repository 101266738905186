import React, { FC } from "react";
import { PageBanner } from "../../../components/pageBanner";
import { Container } from "react-bootstrap";
import { FooterTop } from "../../../components/footerTop";
import { MainSection } from "./mainSection";
import { DatabaseLifecycleSection } from "./databaseLifecycleSection";
import { ServicesOfferSection } from "./servicesOfferSection";

interface IProps {}

export const DatabaseAdministration: FC<IProps> = ({}) => {
  return (
    <>
      <PageBanner
        bannerImg="./images/services/database-administration/database-administration-banner.webp"
        pageTitle="Expert Soft Solution LLC / Database Administration"
      />
      <Container className="pt-5 pb-3">
        <MainSection />
        <DatabaseLifecycleSection />
        <ServicesOfferSection />
      </Container>
      <FooterTop
        subTitle="Plan to Start a Project"
        title="Our Experts Ready to Help You"
        btnText="Contact Us"
      />
    </>
  );
};
