import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BodyText, Box, DisplayStart } from '../../../components/styledComponents';
import { FaCheckCircle } from 'react-icons/fa';
import { WebAppTestingList, MobileAppTestingList } from '../../../DataJson';
import { CheckFeatureSection } from '../../../components/styledComponents2';

interface IProps {
}

export const CheckFeatureListSection: FC<IProps> = ({ }) => {
    return (
        <>
            <CheckFeatureSection>
                <Container>
                    <Row className='pt-3'>
                        <Col lg={6} sm="12" className='mb-2'>
                            <Box>
                                <BodyText textColor='#454545' fontSize='20px' fontWeight='700' mb="20px">
                                    {WebAppTestingList.heading}
                                </BodyText>
                            </Box>
                            {WebAppTestingList.listData.map((item: TListItem, index: number) => {
                                return (
                                    <>
                                        <DisplayStart className='align-items-start' key={`IWebAppCode-${index + 1}`}>
                                            <Box mr="12px" mt="4px">
                                                <FaCheckCircle size={25} className='color-blue' />
                                            </Box>
                                            <Box>
                                                <BodyText textColor='#454545' fontSize='18px' fontWeight='400' mb="7px">
                                                    {item.listItem}
                                                </BodyText>
                                            </Box>
                                        </DisplayStart>
                                    </>
                                )
                            })}
                        </Col>
                        <Col lg={6} sm="12" key={`IconListing-`}>
                            <Box>
                                <BodyText textColor='#454545' fontSize='20px' fontWeight='700' mb="20px">
                                    {MobileAppTestingList.heading}
                                </BodyText>
                            </Box>
                            {MobileAppTestingList.listData.map((item: TListItem, index: number) => {
                                return (
                                    <>
                                        <DisplayStart className='align-items-start' key={`cdProcesses-${index + 1}`}>
                                            <Box mr="15px" mt="4px">
                                                <FaCheckCircle size={25} className='color-blue' />
                                            </Box>
                                            <Box>
                                                <BodyText textColor='#454545' fontSize='18px' fontWeight='400' mb="7px">
                                                    {item.listItem}
                                                </BodyText>
                                            </Box>
                                        </DisplayStart>
                                    </>
                                )
                            })}
                        </Col>
                    </Row>
                </Container>
            </CheckFeatureSection>
        </>
    );
}