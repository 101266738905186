import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BodyText,
  Box,
  DotLine,
  HeadingTitle,
} from "../../../components/styledComponents";

interface IProps {}

export const MainSection: FC<IProps> = ({}) => {
  return (
    <>
      <Row>
        <Col lg={6} sm={12} className="align-self-start">
          <Box className="pt-2">
            <Box mb="20px">
              <HeadingTitle> Mobile App Development </HeadingTitle>
              <DotLine />
            </Box>
            <Box mt="20px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                There are so many ways to build mobile apps in the market and we
                happen to be experts in some of the best technologies to craft
                custom, efficient and engaging mobile applications. We use
                high-end, latest technologies for building attractive,
                user-friendly and widely accepted iOS and Android mobile
                applications. We have served B2B, B2E, B2C mobile app
                requirements of our clients from various industries. Our experts
                carry out the process of creating applications, from the design
                to clickable prototypes, that will help understand the needs of
                the market and its potential users. Then, our development team
                build robust, secure, scalable mobile solutions and connected
                web platforms.
              </BodyText>
            </Box>
            <Box mb="28px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                We have top mobile app developers who are highly skilled and
                updated with the latest technology trend. Our team works with
                the global firms to transform recognized ideas into stunning &
                inventive mobile Apps. We have rich experience and creativity in
                producing stunning mobile apps for different clients over the
                world.
              </BodyText>
            </Box>
          </Box>
        </Col>
        <Col lg={6} sm={12}>
          <div className="mb-1">
            <img
              className="custom-software-img"
              src="./images/services/mobile-app-development/mobile-app-development.webp"
              alt="mobile app development - Expert Soft Solution LLC"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
