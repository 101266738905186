import React, { FC } from 'react';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { CgMenuGridO } from "react-icons/cg";
import { Menu } from '../../components/menu';

interface IProps {
    isSticky?: boolean,
}

export const MobileMenu: FC<IProps> = ({ isSticky }) => {
    const [show, setShow] = useState<boolean>(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <CgMenuGridO onClick={handleShow} color={isSticky ? '#000' : '#fff'} size={35} />
            <Offcanvas show={show} onHide={handleClose} responsive="lg" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <img src='./images/ESS-Logo-Final.webp' alt='logo' width="100" />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Menu isMobile={true} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
