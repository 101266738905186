import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BodyText, Box, DisplayStart, HeadingTitle } from '../../../components/styledComponents';
import { FaCheckCircle } from 'react-icons/fa';
import { OTPlist } from '../../../commonJSON';

interface IProps {
}

export const BottomTextSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Container>
                <Row>
                    <Col lg={12}>
                        <Box mb="30px">
                            <HeadingTitle fontsize='20px'>Offshore Outsourcing</HeadingTitle>
                            <BodyText fontSize='18px' fontWeight='500'>
                                There had been a very encouraging emphasis by Pakistani government and educational sector in the country that Pakistani
                                IT industry is continuously being enriched by highly talented human resources.
                            </BodyText>
                            <BodyText fontSize='18px' fontWeight='500'>
                                At the same time, different other steps like tax break, subsidized rules and priority for information technology sector has
                                made software development and web development a lucrative activity both for business as well as professionals.
                            </BodyText>
                        </Box>
                        <Box mb="27px">
                            {OTPlist.map((item, index) => {
                                return <>
                                    <DisplayStart mb='3px' className='align-items-start' key={`outsourcingtopakistan-${index + 1}`}>
                                        <Box mr="12px" mt="4px">
                                            <FaCheckCircle size={25} className='color-blue' />
                                        </Box>
                                        <Box>
                                            <BodyText textColor='#454545' fontSize='18px' fontWeight='400' mb="7px">
                                                {item.listItem}
                                            </BodyText>
                                        </Box>
                                    </DisplayStart>
                                </>
                            })}
                        </Box>
                        <Box mb="30px">
                            <HeadingTitle fontsize='20px'>Software Outsourcing</HeadingTitle>
                            <BodyText fontSize='18px' fontWeight='500'>
                                In short, doing software outsourcing to Pakistan is totally profitable to organizations worldwide. You can set up your offshore
                                development offices here in Pakistan and get advantage of cheap workforce without compromising on quality.
                            </BodyText>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </>
    );
}