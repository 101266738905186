import React, { FC, useRef, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  BodyText,
  Box,
  ButtonGradient,
  Select,
  SubmitButton,
  TextArea,
  TextField,
} from "./styledComponents";
import axios from "axios";
import { toast } from "react-toastify";
import { AnyARecord } from "dns";

interface IProps {
  inputBackground?: string;
}

export const GetAQuoteForm: FC<IProps> = ({ inputBackground }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    option: "",
    phoneNumber: "",
    website: "",
    msg: "",
  });
  const [inputsTouch, setInputsTouch] = useState({
    name: false,
    email: false,
    option: false,
    phoneNumber: false,
    website: false,
    msg: false,
  });
  const [emailCheck, setEmailCheck] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    setIsSubmited(false);
    if (name === "email") {
      isEmailValid(value);
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setInputsTouch((prevData) => ({ ...prevData, [name]: true }));
  };
  const handleBlur = (event: any) => {
    const { name, value } = event.target;
    setIsSubmited(false);
    setInputsTouch((prevData) => ({ ...prevData, [name]: true }));
    if (name === "email") {
      isEmailValid(value);
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmited(false);
    const { name, email, option, phoneNumber, website, msg } = formData;

    if (!name || !email || !option || !phoneNumber || !website || !msg) {
      setInputsTouch({
        name: true,
        email: true,
        option: true,
        phoneNumber: true,
        website: true,
        msg: true,
      });
      return;
    }

    console.log("Form Data:", {
      name,
      email,
      option,
      phoneNumber,
      website,
      msg,
    });
    const apiUrl = `https://api.brevo.com/v3/smtp/email`;

    const HTMLTemplate = `<html><head></head><body style="overflow-x:hidden"><div style="padding:15px 20px;position:relative"><img src="https://expertsoftsolution.com/images/ESS-Logo-Final.webp" alt="ess-logo" width="120"><div style="position:absolute;top:20px;width:100%;text-align:center"><h3>Get A Quote Message</h3></div></div><div style="padding:15px 20px;padding-top:0!important"><div><span style="font-size:16px;line-height:24px">Name:<b>${name}</b></span></div><div><span style="font-size:16px;line-height:24px">Email:<b>${email}</b></span></div><div><span style="font-size:16px;line-height:24px">Project Type:<b>${option}</b></span></div><div><span style="font-size:16px;line-height:24px">Phone:<b>${phoneNumber}</b></span></div><div><span style="font-size:16px;line-height:24px">Website:<b>${website}</b></span></div><div><span style="font-size:16px;line-height:24px">Message:<b>${msg}</b></span></div></div><div style="padding:20px;background-color:#1f64a4;text-align:center;color: #fff !important;"><spna style="color: #fff !important;">Expert Soft Solution LLC</spna></div></body></html>`;

    let data = JSON.stringify({
      sender: {
        name: "Expert Soft Solution LLC",
        email: "info@expertsoftsolution.com",
      },
      to: [
        {
          email: "toakhtar@hotmail.com",
          name: "Muhammad Akhtar",
        },
      ],
      subject: "Get A Quote Message From Ess Website",
      htmlContent: HTMLTemplate,
    });

    let config: any = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: {
        accept: "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        "content-type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast("Email Sent", { type: "success" });
        setFormData({
          name: "",
          email: "",
          option: "",
          phoneNumber: "",
          website: "",
          msg: "",
        });
        setIsSubmited(true);
      })
      .catch((error) => {
        console.log(error);
        toast("Failed to send email", { type: "error" });
      });
  };
  function isEmailValid(email: any) {
    if (!!email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      var check = emailRegex.test(email);
      setEmailCheck(check);
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Box mb="56px">
              <TextField
                onBlur={handleBlur}
                onChange={handleChange}
                bgcolor={inputBackground}
                value={formData.name}
                id="name"
                className="name"
                name="name"
                placeholder="Name"
                type="text"
              />
              {inputsTouch.name ? (
                <>
                  {!formData.name && !isSubmited ? (
                    <BodyText textColor="red" fontSize="14px">
                      Required !
                    </BodyText>
                  ) : null}
                </>
              ) : null}
            </Box>
          </Col>
          <Col md={6}>
            <Box mb="56px">
              <TextField
                onBlur={handleBlur}
                onChange={handleChange}
                bgcolor={inputBackground}
                value={formData.email}
                id="email"
                className="email"
                name="email"
                placeholder="E-Mail"
                type="email"
              />
              {inputsTouch.email && (
                <>
                  {!formData.email && !isSubmited ? (
                    <BodyText textColor="red" fontSize="14px">
                      Required !
                    </BodyText>
                  ) : null}
                  {!emailCheck && !!formData.email && !isSubmited ? (
                    <BodyText textColor="red" fontSize="14px">
                      Invalid !
                    </BodyText>
                  ) : null}
                </>
              )}
            </Box>
          </Col>
          <Col md={12}>
            <Box mb="56px">
              <Select
                onBlur={handleBlur}
                onChange={handleChange}
                value={formData.option}
                bgcolor={inputBackground}
                id="option"
                className="option"
                name="option"
                placeholder="Select Options"
              >
                <option value="Select Option">Select Option</option>
                <option value="Project Based">Project Based</option>
                <option value="Team Based">Team Based</option>
              </Select>
              {inputsTouch.option ? (
                <>
                  {(!formData.option || formData.option === "Select Option") &&
                  !isSubmited ? (
                    <BodyText textColor="red" fontSize="14px">
                      Required !
                    </BodyText>
                  ) : null}
                </>
              ) : null}
            </Box>
          </Col>
          <Col md={6}>
            <Box mb="56px">
              <TextField
                onBlur={handleBlur}
                onChange={handleChange}
                bgcolor={inputBackground}
                value={formData.phoneNumber}
                id="phoneNumber"
                className="phoneNumber"
                name="phoneNumber"
                placeholder="Phone Number"
                type="text"
              />
              {inputsTouch.phoneNumber ? (
                <>
                  {!formData.phoneNumber && !isSubmited ? (
                    <BodyText textColor="red" fontSize="14px">
                      Required !
                    </BodyText>
                  ) : null}
                </>
              ) : null}
            </Box>
          </Col>
          <Col md={6}>
            <Box mb="56px">
              <TextField
                onBlur={handleBlur}
                onChange={handleChange}
                bgcolor={inputBackground}
                value={formData.website}
                id="website"
                className="website"
                name="website"
                placeholder="Your Website"
                type="text"
              />
              {inputsTouch.website ? (
                <>
                  {!formData.website && !isSubmited ? (
                    <BodyText textColor="red" fontSize="14px">
                      Required !
                    </BodyText>
                  ) : null}
                </>
              ) : null}
            </Box>
          </Col>
          <Col md={12}>
            <Box mb="56px">
              <TextArea
                onBlur={handleBlur}
                onChange={handleChange}
                bgcolor={inputBackground}
                value={formData.msg}
                id="msg"
                name="msg"
                className="msg"
                placeholder="Your Message Here"
                rows={3}
              />
              {inputsTouch.msg ? (
                <>
                  {!formData.msg && !isSubmited ? (
                    <BodyText textColor="red" fontSize="14px">
                      Required !
                    </BodyText>
                  ) : null}
                </>
              ) : null}
            </Box>
          </Col>
          <Col md={12}>
            <Box mb="56px" mt="20px">
              <SubmitButton type="submit" iscaptilize={true}>
                Submit Now
              </SubmitButton>
            </Box>
          </Col>
        </Row>
      </form>
    </>
  );
};
