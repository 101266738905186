import React, { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BodyText, Box } from "../../../components/styledComponents";
import FSLightbox from "fslightbox-react";

interface IProps {}

export const DastTestSection: FC<IProps> = ({}) => {
  const [securityReport, setSecurityReport] = useState<boolean>(false);
  const [listContent, setListContent] = useState<boolean>(false);
  const [issueType, setIssueType] = useState<boolean>(false);

  return (
    <>
      <Row className="pt-3">
        <Col lg={6} sm={12}>
          <Row>
            <Col lg={6} sm={12}>
              <div className="mb-3">
                <img
                  className="custom-software-img border border-1 border-dark cursor-pointer"
                  src="./images/services/web-security/dast-test-01.webp"
                  alt="dast test 01 - Expert Soft Solution LLC"
                  onClick={() => setSecurityReport(!securityReport)}
                />
                <FSLightbox
                  toggler={securityReport}
                  sources={["./images/services/web-security/dast-test-01.webp"]}
                />
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <div className="mb-3">
                <img
                  className="custom-software-img border border-1 border-dark cursor-pointer"
                  src="./images/services/web-security/dast-test-02.webp"
                  alt="dast test content list - Expert Soft Solution LLC"
                  onClick={() => setListContent(!listContent)}
                />
                <FSLightbox
                  toggler={listContent}
                  sources={["./images/services/web-security/dast-test-02.webp"]}
                />
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <img
              className="custom-software-img border border-1 border-dark cursor-pointer"
              src="./images/services/web-security/dast-test-03.webp"
              alt="issue type - Expert Soft Solution LLC"
              onClick={() => setIssueType(!issueType)}
            />
            <FSLightbox
              toggler={issueType}
              sources={["./images/services/web-security/dast-test-03.webp"]}
            />
          </div>
        </Col>
        <Col lg={6} sm={12} className="align-self-start">
          <Box>
            <Box mb="10px">
              <BodyText textColor="#0a0a0a" fontSize="20px" fontWeight="700">
                DAST Test
              </BodyText>
            </Box>
            <Box>
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="10px"
                mb="7px"
              >
                Expert Soft Solution LLC application security arrangements join
                mechanization, cycle and speed to cost-really dispose of
                weaknesses during programming advancement.
              </BodyText>
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="10px"
                mb="7px"
              >
                As a SaaS application security arrangement, Expert Soft Solution
                LLC makes application security testing basic and cost-effective.
                With Expert Soft Solution LLC's DAST test instrument,
                advancement groups can get to dynamic examination on-request and
                scale easily to fulfill the needs of forceful improvement cutoff
                times. Expert Soft Solution LLC's DAST test requires no interest
                in programming, equipment or security specialists - the
                innovation is not difficult to utilize and upheld by a group of
                a-list specialists who are persistently refining testing
                approaches.
              </BodyText>
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="10px"
                mb="7px"
              >
                Expert Soft Solution LLC gives quick test results through an
                internet based entryway alongside a Fix-First Analysis that
                focuses on outcomes and recognizes imperfections for pressing
                remediation also as issues that can be fixed most rapidly,
                empowering engineers to all the more likely streamline their
                time.
              </BodyText>
            </Box>
          </Box>
        </Col>
      </Row>
    </>
  );
};
