import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BodyText,
  Box,
  DotLine,
  HeadingTitle,
  TesxtRightWrapper,
  TitleDescription,
} from "../../../components/styledComponents";

interface IProps {}

export const MainSection: FC<IProps> = ({}) => {
  return (
    <>
      <Row>
        <Col lg={6} sm={12} className="align-self-start">
          <Box className="pt-2">
            <Box mb="20px">
              <HeadingTitle>Custom Software Development</HeadingTitle>
              <DotLine />
            </Box>
            <Box mt="20px">
              <TitleDescription>
                When it comes to providing custom software development services,
                we only settle for an excellent product with outstanding
                designs. We have the right software development team, right
                skills and abundance of knowledge in building a user-friendly,
                secure, scalable web solution just the way you imagined. That
                makes us a Leading custom Software development company.
              </TitleDescription>
            </Box>
            <Box mb="45px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                We have a proven track record of helping brands to transform
                their businesses with the help of innovative technologies and
                decades of experience in software development. Having the
                skillful team of consultants, designers, developers, managers
                and analysts, we assure long-term growth for your businesses
                along with user-centric designs, increased productivity,
                optimized operations and increased RoI.
              </BodyText>
            </Box>
          </Box>
        </Col>
        <Col lg={6} sm={12}>
          <div className="">
            <img
              className="custom-software-img"
              src="./images/services/custom-software-development/custom-software-development.webp"
              alt="custom software development - Expert Soft Solution LLC"
            />
          </div>
        </Col>
        <Col lg={12} sm={12} className="align-self-start">
          <Box className="pt-1">
            <BodyText
              textColor="#454545"
              fontSize="18px"
              fontWeight="500"
              mt="10px"
              mb="10px"
            >
              We have helped numerous enterprises around the world to establish
              the digital-driven path to unlock the true potential of
              businesses. We focus on serving a wide range of industry segments
              including retail & FMCG, logistic, healthcare, education, travel,
              manufacturing and many more.
            </BodyText>
          </Box>
        </Col>
      </Row>
    </>
  );
};
