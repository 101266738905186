import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box, DisplayCenter, DotLine, HeadingTitle } from '../../components/styledComponents';
import { KeyBox, KeyDifferSection, KeyInnerBox } from '../../components/styledComponents2';
import { keyData } from '../../DataJson';

interface IProps {
}

export const KeyDifferentiatorSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Box>
                <KeyDifferSection>
                    <Row>
                        <Col md={12} sm={12}>
                            <Box mb='20px'>
                                <BodyText textAlign='center' textColor='#e47226' fontWeight='500' fontSize='14px' mr='10px' className='letter-space-1px'>Make Unlike</BodyText>
                                <HeadingTitle textAlign='center'>Key Differentiators</HeadingTitle>
                                <DisplayCenter mt="20px" mb="35px">
                                    <DotLine />
                                </DisplayCenter>
                            </Box>
                        </Col>
                    </Row>
                    <Row>
                        {keyData.map((item: IKeysData, index: number) => {
                            return (
                                <>
                                    <Col lg={4} md={6} sm={12} key={`keyDifferent-${index}`} className='my-2'>
                                        <KeyBox>
                                            <KeyInnerBox>
                                                <Box>
                                                    <img src={item.icon} alt={item.title} className='key-image' />
                                                </Box>
                                                <Box pt='10px'>
                                                    <BodyText textAlign='center' textColor='#0a0a0a' fontWeight='700' fontSize='20px' mr='10px' className='text-nowrap'> {item.title} </BodyText>
                                                    <BodyText fontSize='16px' textAlign='center'>
                                                        {item.description}
                                                    </BodyText>
                                                </Box>
                                            </KeyInnerBox>
                                        </KeyBox>
                                    </Col>
                                </>
                            )
                        })}
                    </Row>
                </KeyDifferSection>
            </Box>
        </>
    );
}