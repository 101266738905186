import React, { FC } from "react";
import { PageBanner } from "../../../components/pageBanner";
import { FooterTop } from "../../../components/footerTop";
import { Container } from "react-bootstrap";
import { MainSection } from "./mainSection";
import { IconBoxSection } from "./iconBoxSection";
import { CheckFeatureListSection } from "./checkFeatureListSection";

interface IProps {}

export const CloudAndDevOps: FC<IProps> = ({}) => {
  return (
    <>
      <PageBanner
        bannerImg="./images/services/cloud-devOps/cloud-devops-banner.webp"
        pageTitle="Expert Soft Solution LLC / Cloud and DevOps"
      />
      <Container className="pt-5 pb-3">
        <MainSection />
        <IconBoxSection />
      </Container>
      <CheckFeatureListSection />
      <FooterTop
        subTitle="Plan to Start a Project"
        title="Our Experts Ready to Help You"
        btnText="Contact Us"
      />
    </>
  );
};
