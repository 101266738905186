import React, { FC } from 'react';
import { Box, DisplayCenter, HeadingTitle, HeadingTopLabel, ProjectBox, ProjectImg, ProjectSectionWrapper, ProjectTitle } from '../../components/styledComponents';
import { Col, Container, Row } from 'react-bootstrap';
import { projectData } from '../../commonJSON';

interface IProps {
}

export const ProjectsSection: FC<IProps> = ({ }) => {
    return (
        <>
            <ProjectSectionWrapper>
                <Container fluid className='px-5 container-lg-p'>
                    <Box mb="40px">
                        <Row>
                            <Col lg={3}></Col>
                            <Col lg={6}>
                                <DisplayCenter flexDirection='column'>
                                    <HeadingTopLabel bgColor='#1273EB4A' textColor='#fff'>PROJECTS</HeadingTopLabel>
                                    <HeadingTitle textAlign='center' textColor='#fff'>Our Recent Developed Projects Includes</HeadingTitle>
                                </DisplayCenter>
                            </Col>
                            <Col lg={3}></Col>
                        </Row>
                    </Box>
                    <Row>
                        {projectData.map((item: IProjectData, index: number) => {
                            return <>
                                <Col lg={4} md={6} key={`project-${index + 1}`} className='align-self-center'>
                                    <ProjectBox>
                                        <ProjectImg src={item.icon} alt={item.title} className={`project-img-${index + 1}`} loading="lazy" />
                                        <ProjectTitle className='project-title'>{item.title}</ProjectTitle>
                                    </ProjectBox>
                                </Col>
                            </>
                        })}
                    </Row>
                </Container>
            </ProjectSectionWrapper>
        </>
    );
}
