import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BodyText,
  Box,
  DotLine,
  HeadingTitle,
} from "../../../components/styledComponents";

interface IProps {}

export const MainSection: FC<IProps> = ({}) => {
  return (
    <>
      <Row>
        <Col lg={6} sm={12} className="align-self-start">
          <Box className="pt-2">
            <Box mb="20px">
              <HeadingTitle> Quality Assurance </HeadingTitle>
              <DotLine />
            </Box>
            <Box mt="20px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                Testing is an essential part of the development process. By
                implementing a rigorous testing procedure into our work, we help
                our clients avoid system bugs, UX issues and a whole host of
                seemingly small errors, which, if left untreated, could lead to
                larger-scale problems. Our software testing services are second
                to none.
              </BodyText>
            </Box>
            <Box mb="28px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                As one of the leading software testing companies in Pakistan,
                with a commitment to serve the best of quality, our Web / Mobile
                App testing teams aim to enhance cost effectiveness and reduce
                rework time by identifying possible issues at early stages of
                the project life cycle with an unbiased assessment of the
                quality of the applications, through a well thought of mix of
                manual as well as automated testing.
              </BodyText>
            </Box>
          </Box>
        </Col>
        <Col lg={6} sm={12}>
          <div className="mb-1">
            <img
              className="custom-software-img"
              src="./images/services/quality-assurance/quality-assurance.webp"
              alt="quality assurance - Expert Soft Solution LLC"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
