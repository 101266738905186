import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FooterAboutUs } from './footerAboutUs';
import { FooterLastWrapper, FooterSecondWrapper, FooterWrapper } from '../../components/styledComponents';
import { FooterMenu } from './footerMenu';
import { FooterContactInfo } from './footerContactInfo';
import { FooterBottom } from './footerBottom';
import { BackTopButton } from '../../components/backTopButton';

interface IProps {
}

export const Footer: FC<IProps> = ({ }) => {
    return (
        <>
            <FooterWrapper>
                <Container fluid className='px-5 container-lg-p'>
                    <Row>
                        <Col lg={4}>
                            <FooterAboutUs />
                        </Col>
                        <Col lg={4}>
                            <FooterSecondWrapper>
                                <FooterMenu />
                            </FooterSecondWrapper>
                        </Col>
                        <Col lg={4}>
                            <FooterLastWrapper>
                                <FooterContactInfo />
                            </FooterLastWrapper>
                        </Col>
                    </Row>
                </Container>
                <BackTopButton />
            </FooterWrapper>
            <FooterBottom />
        </>
    );
}
