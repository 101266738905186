import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BodyText,
  Box,
  DotLine,
  HeadingTitle,
} from "../../../components/styledComponents";

interface IProps {}

export const MainSection: FC<IProps> = ({}) => {
  return (
    <>
      <Row>
        <Col lg={6} sm={12} className="align-self-start">
          <Box className="pt-2">
            <Box mb="20px">
              <HeadingTitle> Web App Development </HeadingTitle>
              <DotLine />
            </Box>
            <Box mt="20px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                The website has become an essential and significant part of
                human life. Every business needs a website to showcase its
                skill, products, talents and much more. Everything can be formed
                and updated with a website. Hyperlink Expert Soft Solution LLC
                understands the necessities and crafts exquisite website and CMS
                systems for start-ups or businesses. We are the leading
                tailor-made web development company, delivering the best web and
                CMS solutions across the globe.
              </BodyText>
            </Box>
            <Box mb="30px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                Our highly skilled developers have proficient expertise in the
                latest technology and trends. We worked with global firms to
                transform recognized ideas into stunning & inventive websites.
                We have rich experience and creativity in producing phenomenal
                websites and CMS development for different clients worldwide.
                Our experts hold a different and unique perspective which helps
                to tailor web development faster and better. With this web
                development, the business runs with high functionality,
                efficiency and enhances it.
              </BodyText>
            </Box>
          </Box>
        </Col>
        <Col lg={6} sm={12}>
          <div className="mb-1">
            <img
              className="custom-software-img"
              src="./images/services/web-app-development/web-app-development.webp"
              alt="web app development - Expert Soft Solution LLC"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
