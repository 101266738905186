import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Box, DisplayCenter, DotLine, HeadingTitle, HeadingTopLabel, ProcessSectionWrapper } from '../../components/styledComponents';

interface IProps {
}

export const OurProcess: FC<IProps> = ({ }) => {
    return (
        <>
            <ProcessSectionWrapper>
                <Container fluid className='px-5 container-lg-p'>
                    <Row>
                        <Col lg={3}></Col>
                        <Col lg={6}>
                            <DisplayCenter flexDirection='column'>
                                <HeadingTopLabel>PROCESS</HeadingTopLabel>
                                <HeadingTitle textAlign='center'>Our Working Process</HeadingTitle>
                            </DisplayCenter>
                        </Col>
                        <Col lg={3}></Col>
                    </Row>
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                            <DisplayCenter mb="40px">
                                <img src='./images/home/our-process.webp' alt='our-process' className='img-fluid' loading="lazy" />
                            </DisplayCenter>
                        </Col>
                        <Col lg={2}></Col>
                    </Row>
                </Container>
            </ProcessSectionWrapper>
        </>
    );
}
