import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PortfolioBoxCard } from '../../../components/portfolioBoxCard';
import { LogoDesignsList } from '../../../DataJson';

interface IProps {
}

export const CardMainSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row className='pt-4 mb-5'>
                {LogoDesignsList.map((item, index) => {
                    return <>
                        <Col lg={4} sm="12" className='mb-4' key={`logoDesignsList-${index + 1}`}>
                            <PortfolioBoxCard
                                image={item.image}
                                title={item.title}
                                titlealign='center'
                                imgWidth='250px'
                                imgheight='188px'
                            />
                        </Col>
                    </>
                })}
            </Row>
        </>
    );
}
