import React, { FC } from "react";
import { PageBanner } from "../../../components/pageBanner";
import { Col, Container, Row } from "react-bootstrap";
import {
  BodyText,
  Box,
  DisplayCenter,
  DotLine,
  HeadingTitle,
  TechImg,
  TechImgBox,
  TechnologiesWrapper,
} from "../../../components/styledComponents";
import { TechnologiesData } from "../../../commonJSON";

interface IProps {}

export const Technologies: FC<IProps> = ({}) => {
  return (
    <>
      <PageBanner
        bannerImg="./images/company/company_banner.webp"
        pageTitle="Expert Soft Solution LLC / Technologies"
      />
      <TechnologiesWrapper>
        <Container>
          <DisplayCenter>
            <Box className="text-center col-lg-7">
              <BodyText
                textAlign="center"
                textColor="#e47226"
                fontWeight="400"
                fontSize="14px"
                className="text-uppercase letter-space-1px"
                mb="10px"
              >
                TECHNOLOGY INDEX
              </BodyText>
              <HeadingTitle textAlign="center" textColor="#fff">
                What technology we are using for our valued customers
              </HeadingTitle>
              <DisplayCenter>
                <DotLine />
              </DisplayCenter>
            </Box>
          </DisplayCenter>
          <Row className="mt-5">
            {TechnologiesData.map((item, index) => {
              return (
                <>
                  <Col lg={3} md={6} key={`technology-logo--${index + 1}`}>
                    <TechImgBox>
                      <TechImg
                        src={item.path}
                        alt="technology-logo"
                        className={item.cssClass ? item.cssClass : ""}
                      />
                    </TechImgBox>
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      </TechnologiesWrapper>
    </>
  );
};
