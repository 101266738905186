import React, { FC } from "react";
import {
  Box,
  ContactList,
  FooterTitle,
} from "../../components/styledComponents";
import { FaRegClock, FaRegEnvelope } from "react-icons/fa";
import { TbPhoneCall } from "react-icons/tb";
import { PiMapPinLine } from "react-icons/pi";
import { Link } from "react-router-dom";

export const ContactListData: IContactList[] = [
  {
    icon: <PiMapPinLine />,
    name: "address",
    title:
      "Expert Soft Solution LLC, 74-A, Quaid-e-Azam Town, Gujranwala, Pakistan",
  },
  {
    icon: <TbPhoneCall />,
    name: "phone",
    title: "+92-301-4728-930",
  },
  {
    icon: <FaRegEnvelope />,
    name: "email",
    title: "info@expertsoftsolution.com",
  },
  {
    icon: <FaRegClock />,
    name: "timing",
    title: "Working Hours: 8:00 am - 8:00 pm",
  },
];

interface IProps {}

export const FooterContactInfo: FC<IProps> = ({}) => {
  return (
    <>
      <Box>
        <FooterTitle>Contact Info</FooterTitle>
      </Box>
      <Box>
        <ContactList>
          {ContactListData.map((item: IContactList, index: number) => {
            if (item.name === "phone") {
              return (
                <>
                  <li key={`${item.name}-${index + 1}`}>
                    <a
                      className="text-decoration-none"
                      href={`tel:${item.title}`}
                    >
                      <span className="contact-icon">{item.icon}</span>
                      <span className="contact-text">{item.title}</span>
                    </a>
                  </li>
                </>
              );
            }

            if (item.name === "email") {
              return (
                <>
                  <li key={`${item.name}-${index + 1}`}>
                    <a
                      className="text-decoration-none"
                      href={`mailto:${item.title}`}
                    >
                      <span className="contact-icon">{item.icon}</span>
                      <span className="contact-text">{item.title}</span>
                    </a>
                  </li>
                </>
              );
            }
            return (
              <>
                <li key={`${item.name}-${index + 1}`}>
                  <span className="contact-icon">{item.icon}</span>
                  <span className="contact-text">{item.title}</span>
                </li>
              </>
            );
          })}
        </ContactList>
      </Box>
    </>
  );
};
