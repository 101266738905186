import React, { FC } from "react";
import { Container } from "react-bootstrap";
import { PageBanner } from "../../../components/pageBanner";
import { FooterTop } from "../../../components/footerTop";
import { MainSection } from "./mainSection";
import { IconBoxSection } from "./iconBoxSection";

interface IProps {}

export const ProductEngineering: FC<IProps> = ({}) => {
  return (
    <>
      <PageBanner
        bannerImg="./images/services/product-engineering/product-engineering-banner.webp"
        pageTitle="Expert Soft Solution LLC / Product Engineering"
      />
      <Container className="pt-5 pb-3">
        <MainSection />
        <IconBoxSection />
      </Container>
      <FooterTop
        subTitle="Plan to Start a Project"
        title="Our Experts Ready to Help You"
        btnText="Contact Us"
      />
    </>
  );
};
