import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box, DisplayStart } from '../../../components/styledComponents';
import { FaCheckCircle } from 'react-icons/fa';
import { DatabaseServiceListing } from '../../../DataJson';
import { BorderLinehorizontal } from '../../../components/styledComponents2';

interface IProps {
}

export const ServicesOfferSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row className='pt-4'>
                <Box mb="40px">
                    <BodyText textColor='#0a0a0a' fontSize='28px' fontWeight='700' mb="15px">
                        Services We Offer
                    </BodyText>
                    <BorderLinehorizontal />
                </Box>
                {DatabaseServiceListing.map((item: IDatabaseServiceData, index: number) => {
                    return (
                        <>
                            <Col lg={6} sm="12" key={`IconListing-${index + 1}`}>
                                <DisplayStart className='align-items-start' mb="20px">
                                    <Box mr="12px">
                                        <FaCheckCircle size={25} className='color-blue' />
                                    </Box>
                                    <Box>
                                        <BodyText textColor='#454545' fontSize='18px' fontWeight='600' mb="5px">
                                            {item.title}
                                        </BodyText>
                                    </Box>
                                </DisplayStart>
                            </Col>
                        </>
                    )
                })}
            </Row>
        </>
    );
}