import React, { FC, useEffect, useState } from 'react';
import { FaChevronUp } from 'react-icons/fa';
import { BiSolidChevronUp } from "react-icons/bi";

interface IProps {
}

export const BackTopButton: FC<IProps> = ({ }) => {

    const [showButton, setShowButton] = useState(false);


    useEffect(() => {

        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 270) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };


    return (
        <>
            {showButton && (
                <button onClick={scrollToTop} className="back-to-top">
                    <FaChevronUp size={24} />
                </button>
            )}
        </>
    );
}
