import React, { FC, useState, useLayoutEffect } from 'react';
import { MainMenu, adminDdMenu } from '../commonJSON';
import { NavLink } from 'react-router-dom';

interface IProps {
    isMobile?: boolean,
    isDesktop?: boolean,
    isMainLyaout?: boolean,
    styleClass?: string
}

export const Menu: FC<IProps> = ({ isMobile, isDesktop, isMainLyaout, styleClass }) => {
    const [menuList, setMenuList] = useState<IMenuItems[]>(MainMenu);
    const handleOpenDD = (index: number) => {
        let temp = [...menuList];
        for (var i = 0; i < temp.length; i++) {
            if (i !== index) {
                temp[i].isOpenSubMenu = false;
            }
        }
        temp[index].isOpenSubMenu = !temp[index].isOpenSubMenu;
        setMenuList(temp);
    }


    return (
        <>
            {!!isDesktop ?
                <>
                    <ul className={`navbar-nav ${styleClass ? styleClass : ''} ${isMainLyaout ? 'removePadding' : ''}`}>
                        {menuList.map((item: IMenuItems, index: number) => {
                            return <>
                                {!!item.subMenu === false ?
                                    <li className="nav-item" key={`${item.label}-${index + 1}`}>
                                        <NavLink className="nav-link" to={!!item.path ? item.path : ''} title={item.label}>{item.label}</NavLink>
                                    </li>
                                    :
                                    <>
                                        <li className="nav-item dropdown" key={`${item.label}-${index + 1}`}>
                                            <NavLink className="nav-link dropdown-toggle event-nav-link" to="javascript:;" data-bs-toggle="dropdown" data-bs-auto-close="true" title={item.label}>{item.label}</NavLink>
                                            <ul className="dropdown-menu shadow">
                                                {item.subMenu?.map((subItem: TSubmenu, subIndex: number) => {
                                                    return <>
                                                        <li key={`${subItem.label}-${index + 1}`}>
                                                            <NavLink className="dropdown-item" to={subItem.path} title={subItem.label}>{subItem.label}</NavLink>
                                                        </li>
                                                    </>
                                                })}
                                            </ul>
                                        </li>

                                    </>
                                }
                            </>
                        })}
                    </ul>
                </>
                :
                null
            }
            {isMobile ?
                <>
                    <ul className="navbar-nav">
                        {menuList.map((item: IMenuItems, index: number) => {
                            return <>
                                {!!item.subMenu === false ?
                                    <li className="nav-item" key={`${item.label}-${index + 1}`}>
                                        <NavLink className="nav-link" to={!!item.path ? item.path : ''} title={item.label}>{item.label}</NavLink>
                                    </li>
                                    :
                                    <li className={`nav-item sub ${item.isOpenSubMenu ? 'sub-open' : ''}`} key={`${item.label}-${index + 1}`}>
                                        <input type="checkbox" checked={item.isOpenSubMenu} id={item.label + '-' + index} name={item.label + '-' + index} />
                                        <NavLink className="nav-link" onClick={() => handleOpenDD(index)} to="javascript:;" title={item.label}>{item.label} </NavLink>
                                        <ul className="submenu">
                                            {item.subMenu?.map((subItem: TSubmenu, subIndex: number) => {
                                                return <>
                                                    <li key={`${subItem.label}-${index + 1}`}>
                                                        <NavLink className="sub-nav-link" to={subItem.path} title={subItem.label}>{subItem.label}</NavLink>
                                                    </li>
                                                </>
                                            })}
                                        </ul>
                                    </li>
                                }
                            </>
                        })}
                    </ul>
                </>
                :
                null
            }
        </>
    );
}
