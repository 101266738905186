import React, { FC, useState } from 'react';
import { HeaderTop } from './headerTop';
import { Container, Row, Col } from 'react-bootstrap';
import { MobileMenu } from '../../mobileMenu';
import { Box, HeaderWrapper, MobileMenuWrapper } from '../../../components/styledComponents';
import { Menu } from '../../../components/menu';
import { Link } from 'react-router-dom';

interface IProps {
}

export const Header: FC<IProps> = ({ }) => {
    const [isTop, setisTop] = useState<boolean>(false);

    document.addEventListener('scroll', () => {
        if (window.scrollY > 120) {
            setisTop(true);
        } else {
            setisTop(false);
        }
    });
    return (
        <>
            <HeaderTop />
            <HeaderWrapper className={`${isTop ? 'menu-sticky' : ''}`}>
                <Container fluid className='px-5 container-lg-p'>
                    <Row className='position-relative'>
                        <Col lg={3} className='text-start'>
                            <Link to="/">
                                <img src="./images/ESS-Logo-Final.webp" alt="ESS Logo" />
                            </Link>
                        </Col>
                        <Col lg={9}>
                            <Box className='text-start home-menu'>
                                <Menu isDesktop={true} styleClass='sticky' />
                            </Box>
                            <Box className='text-end mobile-menu'>
                                <MobileMenuWrapper>
                                    <MobileMenu isSticky={isTop ? true : false} />
                                </MobileMenuWrapper>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </HeaderWrapper>
        </>
    );
}
