import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Box } from '../../../components/styledComponents';
import { PortfolioBoxCard } from '../../../components/portfolioBoxCard';
import { PHPProjects } from '../../../commonJSON';

interface IProps {
}

export const CardMainSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row className='pt-4 mb-5'>
                {PHPProjects.map((item, index) => {
                    return <>
                        <Col lg={4} sm="12" className='mb-4' key={`phpprojects-${index + 1}`}>
                            <PortfolioBoxCard
                                image={item.image}
                                title={item.title}
                                subTitle={item.subTitle}
                                description={item.description}
                                linkText={item.linkText}
                                linkUrl={item.linkUrl}
                            />
                        </Col>
                    </>
                })}
            </Row>
        </>
    );
}
