import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BodyText,
  Box,
  DotLine,
  HeadingTitle,
} from "../../../components/styledComponents";

interface IProps {}

export const MainSection: FC<IProps> = ({}) => {
  return (
    <>
      <Row>
        <Col lg={6} sm={12} className="align-self-start">
          <Box className="pt-2">
            <Box mb="20px">
              <HeadingTitle> Product Engineering </HeadingTitle>
              <DotLine />
            </Box>
            <Box mt="20px">
              <BodyText
                textColor="#454545"
                fontSize="18px"
                fontWeight="500"
                mt="15px"
                mb="25px"
              >
                Technology-driven businesses require constant enhancement, a
                revolutionary approach and an agile mindset to foster growth.
                Today’s disruptive technologies paired with a digital roadmap
                and high-potential talents can lead the digital space. Expert
                Soft Solution LLC, with over 10+ years of experience in
                delivering diverse and dynamic solutions, helps businesses right
                from validating ideas, building product roadmap, developing and
                continuously delivering business-centric, simple and seamless
                software products. Our software engineering and discovery
                workshops help decide the most suitable technology stack for
                your ideas, bringing ideas to life through pragmatic, premier
                and powerful solutions. Our team of architects, consultants,
                designers and engineers help you modernize your existing product
                can meet the ever-changing business requirement in the era of
                cloud, data and collaboration.
              </BodyText>
            </Box>
          </Box>
        </Col>
        <Col lg={6} sm={12}>
          <div className="mb-2">
            <img
              className="custom-software-img"
              src="./images/services/product-engineering/product-engineering.webp"
              alt="product engineering - Expert Soft Solution LLC"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
