import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box, CyberIconBox, DisplayStart } from '../../../components/styledComponents';
import { FaCheckCircle } from 'react-icons/fa';
import { CyberSecurityIconList } from '../../../DataJson';

interface IProps {
}

export const IconBoxSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row className='pt-3 pb-1'>
                {CyberSecurityIconList.map((item: ICyberSecurity, index: number) => {
                    return (
                        <>
                            <Col lg={6} className='mb-4' sm="12" key={`ICustomListing-${index + 1}`}>
                                <CyberIconBox>
                                    <DisplayStart className='align-items-center flex-column' mb="20px">
                                        <Box mb="10px">
                                            {/* <FaCheckCircle size={50} className='text-white' /> */}
                                            <img src={item.icon} alt='icon-image' />
                                        </Box>
                                        <Box>
                                            <BodyText className='text-center' textColor='#fff' fontSize='22px' fontWeight='700' mb="10px">
                                                {item.title}
                                            </BodyText>
                                            <BodyText className='text-center' textColor='#fff' fontSize='18px' fontWeight='500'>
                                                {item.description}
                                            </BodyText>
                                        </Box>
                                    </DisplayStart>
                                </CyberIconBox>
                            </Col>
                        </>
                    )
                })}
            </Row>
        </>
    );
}