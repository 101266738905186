import React, { FC, Suspense, lazy } from "react";
import {
  AboutSectionShapeImg,
  AboutUsSectionWrapper,
  BodyText,
  Box,
  ButtonGradient,
  HeadingTitle,
  HeadingTopLabel,
  ShapeTiltTop,
  TesxtRightWrapper,
  TitleDescription,
} from "../../components/styledComponents";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { AboutUsImg } from './aboutUsImg';

const AboutUsImg = lazy(() =>
  import("./aboutUsImg").then((module) => ({ default: module.AboutUsImg }))
);

interface IProps {}

export const AboutUsSection: FC<IProps> = ({}) => {
  return (
    <>
      <AboutUsSectionWrapper>
        <ShapeTiltTop>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              className="shape-fill"
              d="M737.9,94.7L0,0v100h1000V0L737.9,94.7z"
            ></path>
          </svg>
        </ShapeTiltTop>
        {/* <Container fluid className='px-5 container-lg-p'> */}
        <Container className="pt-5 pb-3">
          <Row>
            <Col lg={6}>
              <Suspense>
                <AboutUsImg />
              </Suspense>
            </Col>
            <Col lg={6} className="align-self-start">
              <TesxtRightWrapper>
                <Box mb="20px">
                  <HeadingTopLabel>ABOUT US</HeadingTopLabel>
                  <HeadingTitle>
                    We Are Increasing Business Success With Technology
                  </HeadingTitle>
                  <TitleDescription>
                    Over 10+ years working in IT services developing software
                    applications and mobile apps for clients all over the world.
                  </TitleDescription>
                </Box>
                <Box mb="45px">
                  <BodyText textColor="#000" fontSize="19px" fontWeight="500">
                    Expert Soft Solution LLC is a leading Software Provider
                    company that takes pride in its innovative approach to IT
                    solutions and services; catering to a substantial and
                    diversified client base.
                  </BodyText>
                </Box>
                <Box>
                  <Link className="text-decoration-none text-white" to="/about">
                    <ButtonGradient>Learn More</ButtonGradient>
                  </Link>
                </Box>
              </TesxtRightWrapper>
            </Col>
          </Row>
        </Container>
      </AboutUsSectionWrapper>
    </>
  );
};
