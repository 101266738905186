import React, { FC } from 'react';
import { Box, DisplayCenter } from './styledComponents';
import { BannerTitle, BannerImg } from './styledComponents2';


interface IProps {
    bannerImg: string,
    pageTitle: string
}

export const PageBanner: FC<IProps> = (props: IProps) => {

    return (
        <>
            <Box>
                <BannerImg bgimg={props.bannerImg}>
                    <BannerTitle>
                        <DisplayCenter className='h-100'>
                            {props.pageTitle}
                        </DisplayCenter>
                    </BannerTitle>
                </BannerImg>
            </Box>
        </>
    );
}
