import React, { FC, lazy, Suspense } from 'react';
import { HomePageSlider } from './slider';
import { Container } from 'react-bootstrap';
import { HomeContentWrapper } from '../../components/styledComponents';
import { OurSolutionsSection } from './ourSolutionsSection';
import { AboutUsSection } from './aboutUsSection';
import { ServicesSection } from './servicesSection';
import { ProjectsSection } from './projectsSection';
import { OurProcess } from './ourProcess';
import { AutoIncrementSection } from './autoIncrementSection';
import { FooterTop } from '../../components/footerTop';
import { GetAQuote } from './getAQuote';

// const HomePageSlider = lazy(() => import('./slider').then(module => ({ default: module.HomePageSlider })));
// const ServicesSection = lazy(() => import('./servicesSection').then(module => ({ default: module.ServicesSection })));
// const ProjectsSection = lazy(() => import('./projectsSection').then(module => ({ default: module.ProjectsSection })));
// const OurProcess = lazy(() => import('./ourProcess').then(module => ({ default: module.OurProcess })));


interface IProps {
}

export const Home: FC<IProps> = ({ }) => {
    return (
        <>
            <Suspense fallback={<div className='fallback'></div>}>
            </Suspense>
            <HomePageSlider />
            <HomeContentWrapper>
                {/* <Container fluid className='px-5 container-lg-p'> */}
                <Container className='pt-5 pb-3'>
                    <OurSolutionsSection />
                </Container>
                <AboutUsSection />
                <ServicesSection />
                <ProjectsSection />
                <OurProcess />
                <AutoIncrementSection />
                <GetAQuote />
            </HomeContentWrapper>
            <FooterTop title="Grow Your Business and Build Your Website or Software With us." btnText="Get In Touch" />
        </>
    );
}
