import React, { FC } from 'react';
import { Header } from './header';
import { Outlet } from 'react-router-dom';
import { MainLayoutWrapper } from '../../components/styledComponents';
import { Footer } from '../footer';

interface IProps {
}

export const MainLayout: FC<IProps> = ({ }) => {
    return (
        <>
            <MainLayoutWrapper>
                <Header />
                <Outlet />
                <Footer />
            </MainLayoutWrapper>
        </>
    );
}
