import React, { FC, useState } from 'react';
import { Header } from './header/index';
import { Outlet } from 'react-router-dom';
import { HomePageWrapper } from '../../components/styledComponents';
import { Footer } from '../footer';

interface IProps {
}

export const HomeLayout: FC<IProps> = ({ }) => {

    return (
        <>
            <HomePageWrapper>
                <Header />
                <Outlet />
                <Footer />
            </HomePageWrapper>
        </>
    );
}
