import React, { FC } from "react";
import {
  BodyText,
  Box,
  DisplayStart,
  FooterSocial,
} from "../../components/styledComponents";
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";

interface IProps {}

export const FooterAboutUs: FC<IProps> = ({}) => {
  return (
    <>
      <Box mb="20px">
        <DisplayStart>
          <Link to="/">
            <img
              src="./images/ESS-Logo-Final.webp"
              alt="Expert Soft Solution LLC"
            />
          </Link>
        </DisplayStart>
      </Box>
      <Box mb="20px">
        <BodyText fontWeight="600" lineHeight="28px" fontSize="16px">
          Pakistan Software Export Board
        </BodyText>
        <BodyText fontWeight="600" lineHeight="28px" fontSize="16px">
          Reg. #: Z-25-6783/22
        </BodyText>
        <BodyText lineHeight="28px" fontSize="16px">
          Expert Soft Solution LLC is a leading Software Provider company that
          takes pride in its innovative approach to IT solutions and services.
        </BodyText>
      </Box>
      <Box mb="20px">
        <DisplayStart>
          <FooterSocial>
            <li>
              <a
                href="https://www.facebook.com/ExpertSoftSolution"
                target="_blank"
              >
                <span>
                  <FaFacebookF />
                </span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/ExpertSoftSolut " target="_blank">
                <span>
                  <FaTwitter />
                </span>
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/company/expert-soft-solution/ "
                target="_blank"
              >
                <span>
                  <FaLinkedinIn />
                </span>
              </a>
            </li>
          </FooterSocial>
        </DisplayStart>
      </Box>
    </>
  );
};
