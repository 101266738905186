import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box, DotLine, HeadingTitle } from '../../../components/styledComponents';

interface IProps {
}

export const InnerParaSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row>
                <Col lg={12} sm={12}>
                    <Box mb='10px'>
                        <BodyText textColor='#0a0a0a' fontSize='20px' fontWeight='700'>
                            Enforce a strong password policy
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            It is critical to utilize solid passwords. Programmers much of the time use complex programming that utilization beast power to break passwords. To safeguard against animal power, passwords should be complicated, containing capitalized letters, lowercase letters, numerals and exceptional characters. Your passwords ought to be no less than 10 characters in length. This secret phrase strategy ought to be kept up with all through your association.
                        </BodyText>
                    </Box>
                    <Box mb='10px'>
                        <BodyText textColor='#0a0a0a' fontSize='20px' fontWeight='700'>
                            Encode your login pages
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            Use SSL encryption on your login pages. SSL allows sensitive information such as credit card numbers, social security numbers and login credentials to be transmitted securely. Information entered on a page is encrypted so that it's meaningless to any third party who might intercept it. This helps to prevent hackers from accessing your login credentials or other private data.
                        </BodyText>
                    </Box>
                    <Box mb='10px'>
                        <BodyText textColor='#0a0a0a' fontSize='20px' fontWeight='700'>
                            Keep your website clean
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="10px" mb="7px">
                            Each data set, application, or module on your site is one more conceivable place of passage for programmers. You ought to erase any documents, information bases, or applications from your site that are as of now not being used. It is likewise vital to keep your record structure coordinated to monitor changes and make it simpler to erase old documents.
                        </BodyText>
                    </Box>
                </Col>
            </Row>
        </>
    );
}