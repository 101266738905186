import React, { FC } from "react";
import { PageBanner } from "../../../components/pageBanner";
import { Container } from "react-bootstrap";
import {
  BodyText,
  Box,
  DisplayCenter,
  DotLine,
  HeadingTitle,
} from "../../../components/styledComponents";
import { CardMainSection } from "./cardMainSection";

interface IProps {}

export const MobileApps: FC<IProps> = () => {
  return (
    <>
      <PageBanner
        bannerImg="./images/designs/mobileApps/mobile-apps-banner.webp"
        pageTitle="Expert Soft Solution LLC / Mobile Apps"
      />
      <Container fluid className="px-5 container-lg-p">
        <Box mb="40px" mt="60px">
          <DisplayCenter>
            <Box className="text-center">
              <BodyText
                textAlign="center"
                textColor="#e47226"
                fontWeight="400"
                fontSize="14px"
                className="text-uppercase letter-space-1px"
                mb="10px"
              >
                MOBILE APPS
              </BodyText>
              <HeadingTitle textAlign="center">Our Portfolio</HeadingTitle>
              <DisplayCenter>
                <DotLine />
              </DisplayCenter>
            </Box>
          </DisplayCenter>
          <CardMainSection />
        </Box>
      </Container>
    </>
  );
};

// export default MobileApps
